<template>
  <div class="container dashboard-container">
    <div class="row">
      <div class="col-lg-9 col-sm-12">
        <div class="row">
          <div class="col-12">
            <tws-dashboard-operational-news :net-info-link-name="netInformationPage" />
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <tws-dashboard-informational-news
              :title="$t('news.heading')"
              :link="newsViewPage"
              :news="news" />
          </div>
        </div>

        <div
          v-if="news.length"
          class="row">
          <div class="col-12">
            <tws-nav-link
              :href="newsListLink()"
              class="news-archive-link">
              {{ $t('news.more') }}
              <tws-arrow-right-light-icon />
            </tws-nav-link>
          </div>
        </div>
      </div>

      <div class="col-lg-3 col-sm-12">
        <tws-dashboard-document-links :heading="documentLinksHeading" />
      </div>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import OperationalNews from './dashboard-operational-news'
import InformationalNews from './dashboard-informational-news'
import DocumentLinks from './dashboard-document-links'
import TwsArrowRightLightIcon from '@/img/svg/arrow-right-light.svg'

import { TwsNavLink } from 'tws-vue-components'

export default {
  components: {
    'tws-dashboard-operational-news': OperationalNews,
    'tws-dashboard-informational-news': InformationalNews,
    'tws-dashboard-document-links': DocumentLinks,
    TwsNavLink,
    TwsArrowRightLightIcon
  },
  props: {
    netInformationPage: {
      type: String,
      required: true
    },
    newsViewPage: {
      type: String,
      required: true
    },
    documentLinksHeading: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState('news', ['news'])
  },
  mounted () {
    this.loadAllNews({
      page: 1,
      size: 10
    })
  },
  methods: {
    ...mapActions('news', ['loadAllNews']),
    newsListLink () {
      return process.env.VUE_APP_NEWS_ARCHIVE_PATH
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-container {
  margin-top: 48px;
  margin-bottom: 48px;
}

.news-archive-link {
  margin-top: 20px;
  float: right;
}
</style>
