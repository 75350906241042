/* eslint-disable no-useless-escape */

const EMAIL_RESTRICTED_REGEXP = /^(?!.*@(qq\.com|163\.com)$)[a-zA-Z0-9åäöÅÄÖ._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
const EMAIL_REGEXP = /^(([a-zA-Z0-9\-\!#\$%&\'\*\+\/\=\?\^_`\{\|\}~]+(\.[a-zA-Z0-9\-_\!#\$%&\'\*\+/\=\?\^`\{\|\}~]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|([a-zA-ZåäöÅÄÖ0-9]+(\-[a-zA-ZåäöÅÄÖ0-9]+)*(\.[a-zA-ZåäöÅÄÖ0-9]+(\-[a-zA-ZåäöÅÄÖ0-9]+)*)*\.([a-zA-Z0-9]+[a-zA-Z][a-zA-Z0-9]*|[a-zA-Z0-9]*[a-zA-Z][a-zA-Z0-9]+)))$/
const PHONE_REGEXP = /(\+)\d+$/

export const phone = value => {
  return !value || PHONE_REGEXP.test(value)
}

export const email = value => {
  return !value || EMAIL_REGEXP.test(value)
}

export const emailRestricted = value => {
  return !value || EMAIL_RESTRICTED_REGEXP.test(value)
}
