import {
  fetchCaseDetails,
  putAttachments,
  removeAttachment
} from '@/apps/customer-tickets/service/ticket-billing.service'
import { convertDateTime } from '@/utils/filters.utils'

const modifyResponseDetails = (response) => {
  const notesFromAttachments = response.attachments?.map(att => ({
    text: att.name,
    date: att.validFor?.startDateTime,
    author: att.author || 'system',
    visibility: 'external',
    subject: 'Bilaga tillagd'
  })) || []

  const combinedNotes = [
    ...notesFromAttachments,
    ...response.activityLog || []
  ]

  const sortedNotes = combinedNotes.sort((a, b) => new Date(b.date) - new Date(a.date))

  return {
    ...response,
    updatedDate: response.updatedDate && convertDateTime(response.updatedDate),
    attachments: response.attachments?.map(att => ({
      href: `${process.env.VUE_APP_TELIA_NOW_INTEGRATION}/billing/case/${response.caseId}/attachment/${att.id}`,
      fileName: att.name
    })),
    activityLog: sortedNotes
  }
}

export default {
  namespaced: true,
  state: {
    response: null,
    loading: true,
    exception: false,
    attachmentsLoading: false,
    attachmentsException: false,
    removeAttachmentLoading: false
  },
  mutations: {
    setResponse: (state, response) => state.response = response,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setAttachmentsLoading: (state, loading) => state.attachmentsLoading = loading,
    setAttachmentsException: (state, exception) => state.attachmentsException = exception,
    setRemoveAttachmentLoading: (state, loading) => state.removeAttachmentLoading = loading,
    addNote: (state, note) => {
      state.response.activityLog = [
        note,
        ...state.response.activityLog
      ]
    },
    updateAttachments: (state, attachments) => {
      state.response.attachments = attachments
    },
    addNotes: (state, notes) => {
      state.response.activityLog = [
        ...notes,
        ...state.response.activityLog
      ]
    }
  },
  getters: {
    attachments: (state) => state.response.attachments.map(attachment => {
      return { fileName: attachment.name }
    }),
    caseId: (state) => state.response.caseId
  },
  actions: {
    async fetchDetails ({ commit }, id) {
      commit('setLoading', true)
      commit('setException', false)

      try {
        const response = await fetchCaseDetails(`${id}?timestamp=${new Date().getTime()}`)

        if (!response) {
          throw new Error('Failed to fetch case details')
        }

        const modifiedResponse = modifyResponseDetails(response)

        commit('setResponse', modifiedResponse)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    async putAttachments ({ commit, getters }, files) {
      commit('setAttachmentsLoading', true)
      commit('setAttachmentsException', false)

      try {
        const response = await putAttachments(getters.caseId, files)

        if (!response) {
          throw new Error('Failed to put note')
        }

        const notes = files.map(file => ({
          text: file.name,
          date: new Date(),
          author: 'r08341',
          visibility: 'external',
          subject: 'Bilaga tillagd'
        }))

        commit('addNotes', notes)

        const modifiedResponse = {
          ...response,
          updatedDate: response.updatedDate && convertDateTime(response.updatedDate),
          attachments: response.attachments && response.attachments.map(att => ({
            href: `${process.env.VUE_APP_TELIA_NOW_INTEGRATION}/billing/case/${response.caseId}/attachment/${att.id}`,
            fileName: att.name
          }))
        }

        commit('updateAttachments', modifiedResponse.attachments)
      } catch (error) {
        commit('setAttachmentsException', true)
      } finally {
        commit('setAttachmentsLoading', false)
      }
    },
    async removeAttachment ({ commit, getters }, fileKey) {
      try {
        commit('setRemoveAttachmentLoading', true)
        commit('setAttachmentsException', false)

        const response = await removeAttachment(getters.caseId, fileKey)
        commit('setResponse', response)
      } catch {
        commit('setAttachmentsException', true)
      } finally {
        commit('setRemoveAttachmentLoading', false)
      }
    },
    addNote ({ commit }, text) {
      commit('addNote', {
        text: text,
        date: new Date(),
        author: 'r08341',
        visibility: 'external'
      })
    },
    updateCaseResponse ({ commit }, response) {
      commit('setResponse', response)
    }
  }
}
