import Vue from 'vue'
import VueRouter from 'vue-router'

import { isLoggedIn } from '@/utils/user.utils'

import authRoutes from './auth-routes'
import basicInfoRoutes from './basic-info-routes'
import dashboardRoutes from './dashboard-routes'
import networkInformationRoutes from './network-information-routes'
import contactRoutes from './contact-routes'
import newsArchiveRoutes from './news-archive-routes'
import ticketsRoutes from './tickets-routes'
import productsRoutes from '@/apps/products/router/routes'
import pagesRoutes from '@/apps/pages/router/routes'
import PublicLandingNew from '@/apps/public-landing/views/PublicLandingNew.vue'
import SiteSearch from '@/views/site-search'
import MarketConsultations
  from '@/views/market-consultations/MarketConsultations.vue'
import chatRoutes from '@/router/chat-routes'
import CallbackView from '@/views/callback/CallbackView.vue'
import { USER_FIELDS } from '@/common/auth-constants'
import { checkAndRefreshAccessToken } from '@/common/auth-utils'

// Solution navigation guards rerouting to different paths or navigating to the same route.
// More on this issue can be read here:
// https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      return err
    }

    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

const routes = [{
  path: '/:locale(en)?',
  component: {
    template: '<router-view />'
  },
  children: [
    {
      path: '',
      name: 'landing-page',
      component: PublicLandingNew
    },
    ...authRoutes,
    {
      path: 'search-result',
      name: 'search-result',
      component: SiteSearch
    },
    {
      path: 'callback',
      name: 'callback',
      component: CallbackView
    },
    {
      path: 'samrad',
      name: 'market-consultations',
      component: MarketConsultations,
      meta: {
        requiresAuth: true
      }
    },
    dashboardRoutes,
    networkInformationRoutes,
    contactRoutes,
    newsArchiveRoutes,
    ticketsRoutes,
    ...productsRoutes,
    ...basicInfoRoutes,
    ...pagesRoutes,
    ...chatRoutes,
    {
      path: '/en/natinformation/servicelaget-koppar',
      redirect: '/en/natinformation/natforandringar'
    },
    {
      path: '/natinformation/servicelaget-koppar',
      redirect: '/natinformation/natforandringar'
    },
    {
      path: '/en/natinformation/kopparnatsfel',
      redirect: '/en/natinformation/natforandringar'
    },
    {
      path: '/natinformation/kopparnatsfel',
      redirect: '/natinformation/natforandringar'
    }
  ]
}]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return {
      x: 0,
      y: 0
    }

  }
})

function getAllRequiredRoles (to) {
  const rolesRequiredToAccess = []
  to.matched.forEach(r => {
    if (r?.meta?.rolesRequired) {
      rolesRequiredToAccess.push(...r.meta.rolesRequired)
    }
  })
  return rolesRequiredToAccess
}

function hasRequiredRoles (to) {
  const userRoles = JSON.parse(localStorage.getItem(USER_FIELDS.ROLES))
  const rolesRequiredToAccess = getAllRequiredRoles(to)

  const doesNotRequireRolesToAccess = rolesRequiredToAccess.length <= 0
  const hasAnyRequiredRole = userRoles.some(record => rolesRequiredToAccess.includes(record))

  return !!(doesNotRequireRolesToAccess || hasAnyRequiredRole)
}

router.beforeEach(async (to, _from, next) => {
  if (to.name !== 'callback') {
    await checkAndRefreshAccessToken()
  }

  const locale = document.documentElement.lang
  if (to.matched.some(record => record.meta.requiresAuth || record.meta.rolesRequired)) {
    try {
      if (!isLoggedIn() || !hasRequiredRoles(to)) {
        const loginPath = locale === 'en' ? `/${locale}/login` : '/login'
        next({
          path: loginPath,
          query: {
            action: 'login-required',
            dMessage: 401,
            ct_orig_uri: to.fullPath
          },
          params: {
            locale: locale
          }
        })
        return
      }

      next()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  } else {
    next()
  }
})

router.afterEach((to) => {
  const metaTitle = to.meta.title
  document.title = metaTitle || 'Telia Wholesale'
})

export default router
