<template>
  <div class="pages">
    <template v-if="loading">
      <InfraHeroPlaceholder />

      <InfraBannerPlaceholder />
    </template>

    <div
      v-else-if="exception"
      class="container exception-alert">
      <div class="row">
        <div class="col-lg-5 col-md-6 col-sm-6">
          <InfraPageAlert
            type="danger">
            {{ $t('content.exception') }}
          </InfraPageAlert>
        </div>
      </div>
    </div>

    <template v-else-if="content.contentfulType === 'content-page-assembly'">
      <GrootHeroBanner
        :image="content.banner.backgroundImage"
        :title="content.banner.title"
        :preamble="content.banner.description"
        :breadcrumbs="breadcrumbItems" />

      <GrootPreambleWrapper
        v-if="content.preamble"
        :title="content.preamble.title"
        :description="content.preamble.description"
        :uniqueSellingPoints="content.preamble.uniqueSellingPoints" />

      <div class="container content-sections">
        <GrootContentSection
          v-for="(section, index) in content.contentSections"
          :key="index"
          :richBodyText="section.richBodyText"
          :image="section.image"
          :link="section.link" />
      </div>

      <div
        v-if="content.informationBoxes"
        class="container information-boxes-wrapper">
        <PurpurHeading
          tag="h2"
          variant="title-200">
          {{ content.informationBoxes.title }}
        </PurpurHeading>

        <div
          :class="`information-boxes ${content.informationBoxes.items.length === 3 ? 'information-boxes--three-cols' : 'information-boxes--two-cols'}`">
          <GrootInformationBox
            v-for="infoBox in content.informationBoxes.items"
            :key="infoBox.title"
            :title="infoBox.title"
            :richBodyText="infoBox.richBodyText"
            :link="infoBox.link" />
        </div>
      </div>

      <GrootBreaker
        v-if="content.breaker"
        variant="full-width"
        :title="content.breaker.title"
        :richBodyText="content.breaker.richBodyText"
        :ctaLink="content.breaker.button"
        className="groot-breaker" />

      <div
        v-if="content.contentSectionsAfterBreaker"
        class="container content-sections">
        <GrootContentSection
          v-for="section in content.contentSectionsAfterBreaker"
          :key="JSON.stringify(section.richBodyText)"
          :richBodyText="section.richBodyText"
          :image="section.image"
          :link="section.link" />
      </div>

      <div
        v-if="content.informationBoxesAfterBreaker"
        class="container information-boxes-wrapper">
        <PurpurHeading
          tag="h2"
          variant="title-200">
          {{ content.informationBoxesAfterBreaker.title }}
        </PurpurHeading>

        <div
          :class="`information-boxes ${content.informationBoxesAfterBreaker.items.length === 3 ? 'information-boxes--three-cols' : 'information-boxes--two-cols'}`">
          <GrootInformationBox
            v-for="infoBox in content.informationBoxesAfterBreaker.items"
            :key="infoBox.title"
            :title="infoBox.title"
            :richBodyText="infoBox.richBodyText"
            :link="infoBox.link" />
        </div>
      </div>

      <div
        v-if="content.faqSections"
        class="faq-wrapper container">
        <Accordion
          v-for="faq in content.faqSections"
          :key="faq.title"
          :title="faq.title"
          titleVariant="title-300">
          <AccordionItem
            v-for="faqItem in faq.items"
            :key="faqItem.answer"
            :title="faqItem.question"
            :value="faqItem.question">
            <RichTextRenderer :document="faqItem.answer" />
          </AccordionItem>
        </Accordion>
      </div>
    </template>

    <template v-else>
      <GrootHeroBanner
        :title="content.banner.heading"
        :preamble="content.banner.description"
        :breadcrumbs="breadcrumbItems" />

      <ContentContainer
        class="mt-5"
        :content="content.content"
        :faq="content.faq" />
    </template>
  </div>
</template>

<script>
import RichTextRenderer from 'contentful-rich-text-vue-renderer'
import {
  mapState, mapActions
} from 'vuex'
import {
  InfraBannerPlaceholder,
  InfraHeroPlaceholder,
  InfraPageAlert
} from 'tws-vue-components'
import {
  GrootPreambleWrapper,
  GrootContentSection,
  GrootInformationBox,
  GrootBreaker,
  GrootHeroBanner
} from 'groot-components'
import { isLoggedIn } from '@/utils/user.utils'
import ContentContainer from '@/apps/pages/containers/ContentContainer'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import PurpurHeading from '../../../apps/public-landing/components/purpur/PurpurHeading.vue'
import { Accordion } from '@purpurds/purpur'

export default {
  components: {
    ContentContainer,
    InfraBannerPlaceholder,
    InfraHeroPlaceholder,
    InfraPageAlert,
    PurpurHeading,
    GrootPreambleWrapper: applyReactInVueWithOptions(GrootPreambleWrapper),
    GrootContentSection: applyReactInVueWithOptions(GrootContentSection, 'group'),
    GrootInformationBox: applyReactInVueWithOptions(GrootInformationBox),
    GrootBreaker: applyReactInVueWithOptions(GrootBreaker),
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner),
    Accordion: applyReactInVueWithOptions(Accordion),
    AccordionItem: applyReactInVueWithOptions(Accordion.Item),
    RichTextRenderer
  },
  computed: {
    ...mapState('Pages', [
      'loading',
      'exception',
      'content'
    ]),
    banner () {
      return {
        heading: this.content?.fields.pageBanner.fields.heading,
        description: this.content?.fields.pageBanner.fields.description
      }
    },
    breadcrumbItems () {
      return [
        {
          title: isLoggedIn() ? this.$t('breadcrumbs.dashboard-logged-in.title') : this.$t('breadcrumbs.dashboard.title'),
          path: isLoggedIn() ? '/dashboard' : '/',
          routerLink: false
        },
        ...this.getMappedBreadcrumbItems()
      ]
    }
  },
  watch: {
    '$route.path' (newPath) {
      this.loadContent(newPath)
    }
  },
  async created () {
    await this.loadContent(this.$route.path)

    if (this.content) {
      document.title = [...this.content.breadcrumbTitles].pop()
    }
  },
  methods: {
    ...mapActions('Pages', ['loadContent']),
    getMappedBreadcrumbItems () {
      const slug = this.content.slug
      const slugParts = slug.split('/')
      const slugs = slugParts.slice(1).map((_, index) => slugParts.slice(0, index + 2).join('/'))

      const breadcrumbTitles = this.content.breadcrumbTitles

      return breadcrumbTitles.map((title, index) => ({
        title: title,
        path: slugs[index + 1],
        routerLink: false
      }))
    }
  }
}
</script>
<style lang="scss" scoped>
@use '@purpurds/tokens/breakpoint/variables' as breakpoints;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;
@import "tws-core-atoms/variables.scss";

.bottom-text {
  margin-top: 10rem;
}

.exception-alert {
  margin-top: 5rem;
}

.content-sections {
  display: flex;
  flex-direction: column;
  gap: spacing.$purpur-spacing-400;
  margin: spacing.$purpur-spacing-400 auto;

  @media (min-width: breakpoints.$purpur-breakpoint-md) {
    gap: spacing.$purpur-spacing-800;
    margin: spacing.$purpur-spacing-800 auto;
  }
}

.information-boxes-wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing.$purpur-spacing-300;
  margin-bottom: spacing.$purpur-spacing-400;

  @media (min-width: breakpoints.$purpur-breakpoint-md) {
    margin-bottom: spacing.$purpur-spacing-800
  }
}

.information-boxes {
  display: grid;
  grid-gap: spacing.$purpur-spacing-300;

  &--two-cols {
    @media (min-width: breakpoints.$purpur-breakpoint-md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &--three-cols {
    @media (min-width: breakpoints.$purpur-breakpoint-md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: breakpoints.$purpur-breakpoint-lg) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

.groot-breaker {
  margin-bottom: spacing.$purpur-spacing-400;

  @media (min-width: breakpoints.$purpur-breakpoint-md) {
    margin-bottom: spacing.$purpur-spacing-800;
  }
}

.faq-wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing.$purpur-spacing-800;
  margin-bottom: 4rem;

  ::v-deep [class^="_purpur-accordion-item__contentText"] {
    max-width: 100%;
  }
}

::v-deep ol {
  margin-bottom: 0px;
}

::v-deep .infra-generic-banner__heading {
  color: black;
}

::v-deep .contentful-rich-text-container {
  padding: 0;
}

::v-deep ul li p, ul li p.paragraph-100 {
  margin: 0 !important;
}
</style>

<style lang="scss">
@import "groot-components/dist/style.css";
</style>
