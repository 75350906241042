var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-details-billing-view"},[(_vm.loading || _vm.exception)?_c('div',[(_vm.loading)?_c('GrootTicketDetailsSkeletonLoader',{attrs:{"language":_vm.locale}}):_vm._e(),(_vm.exception)?_c('GrootErrorPage',{attrs:{"language":_vm.locale,"link":{
        href: '/tickets/list',
        text: _vm.$t('ticket-details.go-back-to-list'),
      }}}):_vm._e()],1):_c('div',[_c('GrootTicketDetailsHeroBanner',{attrs:{"title":_vm.response.name,"subject":_vm.$t('ticket-list.table.case-number') + ': ' + _vm.response.caseId,"link":{
        href: '/tickets/list',
        text: _vm.$t('ticket-details.go-back-to-list'),
      }}}),_c('GrootTicketDetailsBilling',{attrs:{"language":_vm.locale,"status":_vm.response.status,"description":_vm.response.description,"name":_vm.response.name,"caseId":_vm.response.caseId,"updatedDate":_vm.response.updatedDate,"customerReference":_vm.response.customerReference,"watchlist":_vm.response.watchlist,"attachments":_vm.response.attachments}},[_c('GrootConversationBlock',{attrs:{"language":_vm.locale,"notes":_vm.convertedActivityLog,"loading":_vm.notesLoading || _vm.attachmentsLoading,"exception":_vm.notesException,"attachmentsException":_vm.attachmentsException,"submit":_vm.submit,"type":"TELIA_NOW"}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }