import { FORM_DESCRIPTION_PROPERTIES } from '@/apps/fault-reporting/constants/fault-report-form.constants'
import { createInstance } from '@/services/axios.api'
export const api = createInstance(process.env.VUE_APP_TELIA_NOW_INTEGRATION)

//TODO: move reusable stuff from fault reporting somewhere else

const BASE_CONFIG = {
  baseURL: process.env.VUE_APP_TELIA_NOW_INTEGRATION,
  headers: {
    'Accept': 'application/json'
  },
  responseType: 'json'
}

const FORM_DATA_CONFIG = {
  ...BASE_CONFIG,
  headers: {
    ...BASE_CONFIG.headers,
    ...{ 'Content-Type': 'multipart/form-data' }
  }
}

const JSON_CONFIG = {
  ...BASE_CONFIG,
  headers: {
    ...BASE_CONFIG.headers,
    ...{ 'Content-Type': 'application/json' }
  }
}

const createFormData = (request) => {
  const formData = new FormData()
  Object.keys(request).forEach(key => {
    if (key !== FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS) {
      formData.append(key, request[key])
    }
  })

  if (request.attachments) {
    request.attachments.forEach(attachment => {
      formData.append(FORM_DESCRIPTION_PROPERTIES.ATTACHMENTS, attachment)
    })
  }

  return formData
}

export const sendForm = async (request) => {
  const formData = createFormData(request)
  return await api.post('/billing/case/create', formData, FORM_DATA_CONFIG)
}

export const createBillingCase = async (request) => {
  const formData = createFormData(request)
  return await api.post('/billing/case/create', formData, FORM_DATA_CONFIG)
}

export const fetchCaseList = async (payload) => {
  return await api.post('/billing/case/list', payload, JSON_CONFIG)
}

export const fetchBillingCaseList = async (payload) => {
  return await api.post('/billing/case/list', payload, JSON_CONFIG)
}

export const fetchCaseDetails = async (caseId) => {
  return await api.get(`/billing/case/${caseId}`, JSON_CONFIG)
}

export const postComment = async (caseId, request) => {
  return await api.patch(`/billing/case/${caseId}/note`, request, JSON_CONFIG)
}

export const putAttachments = async (caseId, attachmentsReq) => {
  const formData = createFormData({ attachments: attachmentsReq })
  return await api.patch(`/billing/case/${caseId}/attachments`, formData, FORM_DATA_CONFIG)
}

export const removeAttachment = async (caseId, attachmentIndex) => {
  return await api.delete(`/billing/case/${caseId}/attachments/${attachmentIndex}`, FORM_DATA_CONFIG)
}
