import { fetchContent } from '@/apps/pages/services/content.service'
import {
  buildContentPage,
  extractGenericContentPage
} from './transformers/page.transformer'

export const PagesModule = {
  namespaced: true,
  state: {
    loading: true,
    exception: false,
    content: null
  },
  mutations: {
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setContent: (state, content) => state.content = content
  },
  actions: {
    async loadContent ({ commit }, path) {
      try {
        const replacedPath = path.replace(/^\/en\//, '/')

        const response = await fetchContent(replacedPath)

        if (response.heroBanner || response.contentSections) {
          // Content is of ContentPageAssembly content type
          commit('setContent', buildContentPage(response))
        } else {
          // Content is of GenericContentPage content type
          commit('setContent', extractGenericContentPage(response))
        }
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  }
}
