<script>
import {
  chevronLeft,
  chevronRight,
  info
} from '@purpurds/icon'
import Glide, {
  Controls,
  Breakpoints
} from '@glidejs/glide/dist/glide.modular.esm'
import { CarouselVariant } from './variant'
import PurpurIcon from '../purpur/PurpurIcon.vue'
import PurpurHeading from '../purpur/PurpurHeading.vue'

export default {
  components: {
    PurpurIcon,
    PurpurHeading
  },
  props: {
    variant: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chevronLeft,
      chevronRight,
      info
    }
  },
  computed: {
    isSmallVariant () {
      return this.variant === CarouselVariant.SMALL
    },
    isSmallFrameVariant () {
      return this.variant === CarouselVariant.SMALL_FRAME
    },
    breakpoints () {
      return {
        400: {
          perView: 1,
          peek: {
            before: 0,
            after: 50
          }
        },
        600: {
          perView: 1,
          peek: {
            before: 0,
            after: 120
          }
        },
        1024: {
          perView: this.isSmallVariant ? 3 : this.isSmallFrameVariant ? 2 : 1,
          peek: {
            before: 0,
            after: this.isSmallVariant || this.isSmallFrameVariant ? 120 : 180
          }
        },
        1500: {
          perView: this.isSmallVariant ? 4 : this.isSmallFrameVariant ? 3 : 1,
          peek: {
            before: 0,
            after: this.isSmallVariant || this.isSmallFrameVariant ? 120 : 230
          }
        },
        2000: {
          perView: this.isSmallVariant ? 4 : this.isSmallFrameVariant ? 3 : 1,
          peek: {
            before: 0,
            after: this.isSmallVariant || this.isSmallFrameVariant ? 120 : 230
          }
        }
      }
    }
  },
  mounted () {
    const slider = new Glide(this.$refs.glideRef, {
      type: 'slider',
      autoplay: false,
      animationDuration: 300,
      perView: 1,
      gap: 24,
      breakpoints: this.breakpoints,
      peek: {
        before: 0,
        after: 250
      },
      rewind: false,
      bound: true
    }).mount({
      Controls,
      Breakpoints
    })

    return () => slider.destroy()
  }
}

</script>

<template>
  <div
    ref="glideRef"
    class="glide">
    <div
      v-if="title">
      <PurpurHeading
        tag="h1"
        variant="title-400">
        {{ title }}
      </PurpurHeading>
    </div>
    <div
      class="glide__track"
      data-glide-el="track">
      <ul class="glide__slides">
        <slot />
      </ul>
    </div>

    <div
      class="glide__arrows"
      data-glide-el="controls">
      <button
        type="button"
        class="glide__arrows__arrow glide__arrow--left"
        data-glide-dir="<">
        <PurpurIcon
          class="glide__arrows__icon"
          :svg="chevronLeft" />
      </button>
      <button
        type="button"
        class="glide__arrows__arrow glide__arrow--right"
        data-glide-dir=">">
        <PurpurIcon
          class="glide__arrows__icon"
          :svg="chevronRight" />
      </button>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use '@purpurds/tokens/typography/variables' as typography;
@use "@purpurds/tokens/breakpoint/variables" as breakpoints;
@use "@purpurds/tokens/color/variables" as colors;
@use '@purpurds/tokens/border/variables' as borders;
@use '../../assets/scss/_mixins.scss' as mixins;
@use 'src/apps/public-landing/assets/scss/purpur-spacing-variables' as spacing;

$diameter-medium: 56px;
$disabled-gray: rgba(0, 0, 0, 0.1);

.glide {
  display: flex;
  flex-direction: column;
  gap: spacing.$purpur-spacing-400;

  &__track {
    @include mixins.indent-margin;
  }

  &__slides {
    white-space: normal;
    padding: 4 0;
  }

  &__arrows {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin-bottom: 0;

    &__arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      color: colors.$purpur-color-functional-black;
      background-color: colors.$purpur-color-functional-white;

      border-radius: 50%;
      border: borders.$purpur-border-width-xs solid colors.$purpur-color-functional-black;

      width: $diameter-medium;
      height: $diameter-medium;

      transition: background-color 0.15s ease;

      &:hover {
        background-color: colors.$purpur-color-gray-700;
      }

      &:active {
        background-color: colors.$purpur-color-gray-500;
      }

      &:hover,
      &:active {
        color: white;
      }
    }
  }
}

.glide__arrow--disabled {
  pointer-events: none;
  background-color: $disabled-gray;
  color: colors.$purpur-color-gray-300;
  border: borders.$purpur-border-width-xs solid colors.$purpur-color-gray-300;

  &:hover,
  &:active {
    background-color: $disabled-gray;
    color: colors.$purpur-color-gray-300;
  }
}

::v-deep.glide__arrows__icon span {
  width: spacing.$purpur-spacing-300;
  height: spacing.$purpur-spacing-300;
}
</style>
