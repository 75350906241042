<template>
  <div class="filters-wrapper">
    <div class="row">
      <div class="col-lg-3">
        <tws-date-picker
          v-model="data.fromLastLoggedInDate"
          :label="$t('mass-user-deletion.filters.from-date')" />
      </div>
      <div class="col-lg-3">
        <tws-date-picker
          v-model="data.toLastLoggedInDate"
          :label="$t('mass-user-deletion.filters.to-date')" />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <tws-dropdown-group
          id="user-status"
          v-model="data.accountStatus"
          :label="$t('mass-user-deletion.filters.status')">
          <option>{{ $t('common.select') }}</option>
          <option
            v-for="status in accountStatuses"
            :key="status.key"
            :value="status.key">
            {{ $t(status.value) }}
          </option>
        </tws-dropdown-group>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <tws-text-area-group
          id="username-list"
          v-model="data.usernames"
          :label="$t('mass-user-deletion.filters.usernames')" />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <tws-button-primary
          :disabled="loading"
          @click="onSearch">
          {{ $t('mass-user-deletion.search') }}
        </tws-button-primary>
      </div>
    </div>
    <div
      v-show="showNoFilterMessage"
      class="row message-row">
      <div class="col">
        <InfraPageAlert
          type="warning">
          {{ $t('mass-user-deletion.errors.select-filter') }}
        </InfraPageAlert>
      </div>
    </div>
    <div
      v-show="exception"
      class="row message-row">
      <div class="col">
        <InfraPageAlert
          type="danger">
          {{ $t('mass-user-deletion.errors.default') }}
        </InfraPageAlert>
      </div>
    </div>
    <div
      v-show="loading"
      class="row message-row">
      <div class="col">
        {{ $t('mass-user-deletion.loading') }}
      </div>
    </div>
  </div>
</template>

<script>
import { ACCOUNT_STATUS_TEXT_LIST } from '@/constants/account-status.js'

import {
  TwsButtonPrimary,
  TwsDatePicker,
  TwsDropdownGroup,
  InfraPageAlert,
  TwsTextAreaGroup
} from 'tws-vue-components'

export default {
  components: {
    TwsButtonPrimary,
    TwsDatePicker,
    TwsDropdownGroup,
    InfraPageAlert,
    TwsTextAreaGroup
  },
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    exception: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      accountStatuses: ACCOUNT_STATUS_TEXT_LIST,
      showNoFilterMessage: false,
      data: {
        fromLastLoggedInDate: null,
        toLastLoggedInDate: null,
        accountStatus: null,
        usernames: null
      }
    }
  },
  methods: {
    isAnyFilterSelected () {
      for (const key in this.data) {
        if (this.data[key]) {
          return true
        }
      }
      return false
    },
    onSearch () {
      if (this.isAnyFilterSelected()) {
        this.showNoFilterMessage = false

        this.$emit('search', this.data)
      } else {
        this.showNoFilterMessage = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.message-row {
  margin-top: 76px;
}

.filters-wrapper {
  margin-bottom: 48px;
}
</style>
