import { createInstance } from './axios.api'

export const apiPiam = createInstance(process.env.VUE_APP_USER_ADMIN_SERVICE_PIAM)

const UserService = {
  getUsers: async () => {
    return await apiPiam.get('/external/users', { params: {
      timestamp: new Date().getTime()
    } })
  },
  getCurrentUserDetails: async () => {
    return await apiPiam.get('/external/users/me')
  },
  isTeliaSuperAdministrator: async () => {
    return await apiPiam.get('/external/users/me/is-telia-super-administrator')
  },
  getUserDetails: async userDetails => {
    if (userDetails.userId) {
      return await apiPiam.get(`/external/users/${userDetails.userId}`)
    }

    return await apiPiam.get(`/external/users/username/${userDetails.username}`)
  },
  getUserHistory: async userId => {
    return await apiPiam.get(`/external/users/${userId}/history`)
  },
  delete: async userId => {
    return await apiPiam.delete(`/external/users/${userId}`)
  },
  deleteUsersByUserIds: async userIds => {
    return await apiPiam.post('/external/users/mass-delete', {
      userIds: userIds
    })
  },
  transferUsers: async data => {
    return await apiPiam.post('/external/users/mass-user-transfer', data)
  },
  createUser: async (information, systemUser) => {
    if (systemUser) {
      return await apiPiam.post('/external/users/system-user/create', information)
    }
    return await apiPiam.post('/external/users/create', information)
  },
  updatePersonalInformation: async information => {
    return await apiPiam.put('/external/users', information)
  },
  updateAccountInformation: async information => {
    return await apiPiam.put('/external/users/account', information)
  },
  unlockUser: async userId => {
    return await apiPiam.put(`/external/users/${userId}/account-unlock`, {})
  },
  validateUsername: async (username, companyCode, roles) => {
    return await apiPiam
      .post('/external/users/validate-username', {
        username: username,
        companyCode: companyCode,
        roles: roles
      })
      .then(() => true)
      .catch(() => false)
  },
  getCompanyAdmins: async () => {
    return await apiPiam.get('/external/users/company-admins')
  }
}

export default UserService
