const extractBanner = (banner) => {
  if (!banner) {
    return null
  }

  return {
    pebbleBackground:
      banner.fields.pebbleBackground &&
      banner.fields.pebbleBackground.fields.file.url,
    isGreyBackground: banner.fields.isGreyBackground,
    heading: banner.fields.heading,
    description: banner.fields.description
  }
}

const extractFaq = (faq) => {
  if (!faq || !faq.fields) {
    return null
  }

  const fields = faq.fields

  const faqItems = fields.faqItems.map((faqItem) => {
    const faqItemFields = faqItem.fields

    return {
      question: faqItemFields.question,
      answer: faqItemFields.answer
    }
  })

  return {
    heading: fields.heading,
    items: faqItems
  }
}

export const extractGenericContentPage = (response) => {
  return {
    contentfulType: 'generic-content-page',
    breadcrumbTitles: response.breadcrumbTitles,
    banner: extractBanner(response.pageBanner),
    content: response.content,
    slug: response.slug,
    faq: extractFaq(response.faq)
  }
}

// New code for ContentPageAssembly begins
function buildImage (image) {
  if (!image) {
    return undefined
  }

  return {
    url: image.fields.file.url,
    alt: image.fields.file.description || ''
  }
}

function buildCta (cta, file) {
  if (!cta) {
    return undefined
  }

  if (cta?.fields?.text && file?.fields?.file?.url) {
    return {
      title: cta.fields.text,
      url: file.fields.file.url,
      fileName: file.fields.file.fileName
    }
  }

  return {
    title: cta.fields.text,
    url: cta.fields.linkTrigger
  }
}

function buildHeroBanner (banner) {
  return {
    backgroundImage: buildImage(banner.fields.backgroundImage),
    title: banner.fields.heading,
    description: banner.fields.paragraph
  }
}

function buildInfoBoxes (items, type) {
  if (!items || items.length === 0) {
    return undefined
  }

  return items.map((item) => {
    const { title, description, icon, link } = item.fields

    const iconUrl = icon?.fields.file.url

    return {
      title,
      richBodyText: description,
      ...type === 'usp' ? { iconUrl } : {
        link: link ? {
          title: link.fields.text,
          url: link.fields.linkTrigger
        } : undefined
      }
    }
  })
}

function buildContentSections (sections) {
  if (!sections || sections?.length <= 0) {
    return undefined
  }

  return sections.map((section) => {
    return {
      richBodyText: section.fields.paragraph,
      image: section.fields.image
        ? buildImage(section.fields.image)
        : undefined,
      link:
        section.fields.buttonLink && section.fields.buttonText
          ? {
            title: section.fields.buttonText,
            url: section.fields.buttonLink
          }
          : undefined
    }
  })
}

const buildFaqSections = (sections) => {
  if (!sections || sections?.length <= 0) {
    return undefined
  }

  return sections.map((faq) => {
    const items = faq.fields.faqItems.map((item) => {
      return {
        question: item.fields.question,
        answer: item.fields.answer
      }
    })

    return {
      title: faq.fields.heading,
      items: items
    }
  })
}

export const buildContentPage = (response) => {
  const contentPage = {
    contentfulType: 'content-page-assembly',
    slug: response.slug,
    breadcrumbTitles: response.breadcrumbTitles,
    banner: buildHeroBanner(response.heroBanner),
    preamble: response?.preambleTitle && response?.preambleDescription && {
      title: response.preambleTitle,
      description: response.preambleDescription,
      uniqueSellingPoints: buildInfoBoxes(response.preambleUniqueSellingPoints, 'usp')
    },
    contentSections: buildContentSections(response.contentSections),
    informationBoxes: response?.informationBoxesTitle &&
      response?.informationBoxes?.length && {
      title: response.informationBoxesTitle,
      items: buildInfoBoxes(response.informationBoxes, 'info')
    },
    breaker: response?.breakerTitle &&
      response?.breakerDescription && {
      title: response.breakerTitle,
      richBodyText: response.breakerDescription,
      button: buildCta(response.breakerButton, response.breakerFile)
    },
    contentSectionsAfterBreaker: buildContentSections(
      response.contentSectionsAfterBreaker
    ),
    informationBoxesAfterBreaker: response?.informationBoxesTitleAfterBreaker &&
      response?.informationBoxesAfterBreaker?.length && {
      title: response.informationBoxesTitleAfterBreaker,
      items: buildInfoBoxes(response.informationBoxesAfterBreaker, 'info')
    },
    faqSections: buildFaqSections(response.faq)
  }

  return contentPage
}
