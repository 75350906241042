<template>
  <div class="ticket-details-wow-view">
    <div v-if="loading || exception">
      <GrootTicketDetailsSkeletonLoader
        v-if="loading"
        :language="locale" />

      <GrootErrorPage
        v-if="exception"
        :language="locale"
        :link="{
          href: '/tickets/list',
          text: $t('ticket-details.go-back-to-list'),
        }" />
    </div>
    <div v-else>
      <GrootTicketDetailsHeroBanner
        :title="response.subject"
        :subject="
          $t('ticket-list.table.case-number') + ': ' + response.supportId
        "
        :link="{
          href: '/tickets/list',
          text: $t('ticket-details.go-back-to-list'),
        }" />

      <GrootTicketDetailsWOW
        :language="locale"
        :status="response.status"
        :supportId="response.supportId"
        :caseType="response.caseType"
        :product="response.product"
        :lastUpdated="response.lastUpdated"
        :customerReference="response.customerReference"
        :orderId="response.orderId"
        :assetId="response.assetId"
        :responseEmail="response.responseEmail"
        :createdByUserInfo="response.createdByUserInfo"
        :ttId="response.ttId"
        :subject="response.subject"
        :content="response.content"
        :notes="response.notes"
        :attachments="response.attachments">
        <GrootConversationBlock
          :language="locale"
          type="WOW"
          :notes="response.notes"
          :userInfo="response.createdByUserInfo"
          :loading="notesLoading || attachmentsLoading"
          :exception="notesException"
          :attachmentsException="attachmentsException"
          :submit="submit" />
      </GrootTicketDetailsWOW>
    </div>
  </div>
</template>
<script>
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import {
  GrootConversationBlock,
  GrootErrorPage,
  GrootTicketDetailsHeroBanner,
  GrootTicketDetailsSkeletonLoader,
  GrootTicketDetailsWOW
} from 'groot-components'

import {
  mapActions, mapState
} from 'vuex'

export default {
  components: {
    GrootTicketDetailsHeroBanner: applyReactInVueWithOptions(
      GrootTicketDetailsHeroBanner
    ),
    GrootTicketDetailsWOW: applyReactInVueWithOptions(GrootTicketDetailsWOW),
    GrootConversationBlock: applyReactInVueWithOptions(GrootConversationBlock),
    GrootTicketDetailsSkeletonLoader: applyReactInVueWithOptions(
      GrootTicketDetailsSkeletonLoader
    ),
    GrootErrorPage: applyReactInVueWithOptions(GrootErrorPage)
  },
  data () {
    return {
      locale: this.$route.params?.locale
    }
  },
  computed: {
    ...mapState('CustomerTickets/TicketDetails', [
      'response',
      'loading',
      'exception'
    ]),
    ...mapState('CustomerTickets/TicketNotes', [
      'notesLoading',
      'notesException'
    ]),
    ...mapState('CustomerTickets/TicketDetails', [
      'attachmentsLoading',
      'attachmentsException'
    ])
  },
  mounted () {
    this.fetchTicket(this.$route.params.supportId)
  },
  created () {
    this.resetState()
  },
  methods: {
    ...mapActions('CustomerTickets/TicketDetails', [
      'fetchTicket',
      'putAttachments'
    ]),
    ...mapActions('CustomerTickets/TicketNotes', [
      'postNote',
      'resetState'
    ]),
    async submit (form) {

      if (form.message?.length > 0) {
        await this.postNote({
          supportId: this.response.supportId,
          request: {
            subject: this.$t('action-panel.conversation.post-subject'),
            content: form.message
          }
        })
      }

      if (form.files?.length > 0) {
        await this.putAttachments(form.files)
      }
    }
  }
}
</script>
