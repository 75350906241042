<template>
  <div>
    <InfraBreadcrumbContainer
      :items="breadcrumbItems"
      :grey-background="true">
      <generic-banner
        :header="$t('header.administration-categories.reports-administration')" />
    </InfraBreadcrumbContainer>

    <div class="container">
      <div
        v-if="!exception"
        class="row">
        <div
          v-if="!companiesLoading"
          class="col">
          <tws-dropdown-group
            id="company"
            v-model="selectedCompanyCodeProxy"
            :label="$t('common.business')">
            <option :value="null">
              {{ $t('common.all') }}
            </option>
            <option
              v-for="company in companies"
              :key="company.code"
              :value="company.code">
              {{ company.name }}
            </option>
          </tws-dropdown-group>
        </div>
        <div class="col">
          <div class="result-actions">
            <tws-borderless-dropdown
              :items="sortingItems"
              :default-key="sortBy"
              :displayed-value-wrapper="`${$t('common.sort')} ({value})`"
              class="result-action color-light-purple"
              @onChange="onSortChange" />
          </div>
        </div>
      </div>

      <div
        v-if="exception || deletionException || filteredReports.length === 0"
        class="row justify-content-center">
        <div class="col-8">
          <InfraPageAlert
            v-if="exception"
            class="page-alert"
            :message="$t('common.loading-failed')"
            type="danger" />
          <InfraPageAlert
            v-if="deletionException"
            class="page-alert"
            :message="$t('reports-administration.loading-failed')"
            type="danger" />
          <div
            v-if="!loading && filteredReports.length === 0"
            class="col-12 no-results-message-column">
            <p class="preamble">
              {{ $t('reports-administration.no-data') }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="filteredReports.length > 0"
        class="row">
        <div class="col">
          <tws-paginated-table
            :items="filteredReports"
            :current-page="currentPage"
            :extra-page-count="5"
            :item-count-on-page="20"
            @onPageChange="onPageChange">
            <template #thead>
              <tr>
                <th colspan="2">
                  {{ $t('reports-administration.table-headings.filename') }}
                </th>
                <th>{{ $t('reports-administration.table-headings.publishing-date') }}</th>
                <th>{{ $t('reports-administration.table-headings.expiration-date') }}</th>
                <th v-if="!companiesLoading">
                  {{ $t('reports-administration.table-headings.company') }}
                </th>
                <th>{{ $t('reports-administration.table-headings.delete') }}</th>
              </tr>
            </template>

            <template #tr="{ item }">
              <td>
                <tws-file-extension-icon :extension="item.fileExtension" />
              </td>
              <td>
                <a
                  :href="fileDownloadUrl(item.reportId)"
                  class="link filename">
                  {{ item.filename }}
                </a>
              </td>
              <td>{{ item.publishingDate }}</td>
              <td>{{ item.expiryDate }}</td>
              <td v-if="!companiesLoading">
                {{ companyNameByCode.get(item.companyCode) }}
              </td>
              <td>
                <a
                  class="link delete-link"
                  @click="onDeleteClick(item.reportId)">
                  <tws-delete-row-icon class="delete-icon" />
                  {{ $t('reports-administration.delete') }}
                </a>
              </td>
            </template>
          </tws-paginated-table>
        </div>
      </div>

      <confirmation-dialog
        v-show="showDeletionDialog"
        :heading="$t('reports-administration.deletion-dialog.heading')"
        :confirmation-button-text="$t('reports-administration.deletion-dialog.confirm')"
        :cancel-button-text="$t('common.cancel')"
        @confirm="onReportDeletionConfirm"
        @cancel="showDeletionDialog = false">
        <template #body>
          {{ $t('reports-administration.deletion-dialog.body') }}
        </template>
      </confirmation-dialog>
    </div>
  </div>
</template>

<script>
import {
  mapActions,
  mapState
} from 'vuex'

import ConfirmationDialog from '@/components/common/confirmation-dialog.vue'
import TwsFileExtensionIcon from '@/components/common/file-extension-icon.vue'
import GenericBanner from '@/components/common/generic-banner.vue'
import { SortOptions } from '@/store/modules/reports-administration.store'

import { InfraBreadcrumbContainer } from 'tws-vue-components'

import { BREADCRUMBS_REPORTS_ADMINISTRATION } from '@/constants/breadcrumbs-constants'

import {
  InfraPageAlert,
  TwsBorderlessDropdown,
  TwsDropdownGroup,
  TwsPaginatedTable
} from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessDropdown,
    TwsDropdownGroup,
    InfraPageAlert,
    TwsPaginatedTable,
    GenericBanner,
    TwsFileExtensionIcon,
    InfraBreadcrumbContainer,
    ConfirmationDialog
  },
  data () {
    return {
      breadcrumbItems: BREADCRUMBS_REPORTS_ADMINISTRATION,
      showDeletionDialog: false,
      reportIdToDelete: null,
      sortingItems: [
        {
          key: SortOptions.PUBLISHING_DATE_DESC,
          value: this.$t('reports-administration.sort-options.publishing-date-descending')
        },
        {
          key: SortOptions.PUBLISHING_DATE_ASC,
          value: this.$t('reports-administration.sort-options.publishing-date-ascending')
        },
        {
          key: SortOptions.EXPIRY_DATE_DESC,
          value: this.$t('reports-administration.sort-options.expiration-date-descending')
        },
        {
          key: SortOptions.EXPIRY_DATE_ASC,
          value: this.$t('reports-administration.sort-options.expiration-date-ascending')
        },
        {
          key: SortOptions.NAME_DESC,
          value: this.$t('reports-administration.sort-options.name-descending')
        },
        {
          key: SortOptions.NAME_ASC,
          value: this.$t('reports-administration.sort-options.name-ascending')
        }
      ]
    }
  },
  computed: {
    ...mapState('reportsAdministration/loading', [
      'loading',
      'exception',
      'filteredReports',
      'currentPage',
      'sortBy',
      'selectedCompanyCode'
    ]),
    ...mapState('reportsAdministration/deletion', {
      deletionException: 'exception'
    }),
    ...mapState('lookups/companies', {
      companiesLoading: 'loading',
      companies: 'companies'
    }),
    selectedCompanyCodeProxy: {
      get () {
        return this.selectedCompanyCode
      },
      set (value) {
        this.changeSelectedCompanyCode(value)
      }
    },
    companyNameByCode () {
      return new Map(this.companies.map(i => [
        i.code,
        i.name
      ]))
    }
  },
  mounted () {
    this.loadCompanies()
    this.loadReports()
  },
  methods: {
    ...mapActions({
      loadCompanies: 'lookups/companies/fetchCompanies',
      loadReports: 'reportsAdministration/loading/loadReports',
      changeSelectedCompanyCode: 'reportsAdministration/loading/changeSelectedCompanyCode'
    }),
    onPageChange (page) {
      this.$store.dispatch('reportsAdministration/loading/changeCurrentPage', page)
    },
    onSortChange (value) {
      this.$store.dispatch('reportsAdministration/loading/changeSort', value)
    },
    fileDownloadUrl (reportId) {
      return process.env.VUE_APP_REPORT_MANAGEMENT_API + '/reports/fetch?id=' + reportId
    },
    onDeleteClick (reportId) {
      this.reportIdToDelete = reportId
      this.showDeletionDialog = true
    },
    onReportDeletionConfirm () {
      this.$store.dispatch('reportsAdministration/deletion/deleteReport', this.reportIdToDelete)
      this.showDeletionDialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.result-actions {
  justify-content: flex-end;
  display: flex;
}

.filename {
  font-weight: bold;
  color: $black;
}

.infra-page-alert {
  margin-bottom: 2rem;
}

.delete-icon {
  margin-right: 0.9rem;
}

.delete-link {
  color: $black;
  display: flex;
  align-items: center;

  svg {
    margin-bottom: -2px;
    width: 12px;
    height: 12px;
  }

  &:hover {
    color: $core-purple;

    path:first-of-type {
      fill: $core-purple;
    }
  }
}

.no-results-message-column {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  .preamble {
    border: 1px solid $medium-grey;
    padding: 20px 30px;
    max-width: 790px;
  }
}
</style>
