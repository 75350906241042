<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-7 col-md-12">
        <InfraContentfulRichTextContainer
          :content="content" />
      </div>
      <div
        v-if="faq"
        class="col-lg-12 col-md-12 faq">
        <InfraAccordion
          :heading="faq.heading">
          <InfraAccordionItem
            v-for="(item) in faq.items"
            :key="item.answer"
            class="faq--accordion-item"
            :title="item.question">
            <InfraContentfulRichTextContainer
              class="mt-5 mb-5"
              :content="item.answer" />
          </InfraAccordionItem>
        </InfraAccordion>
      </div>
    </div>
  </div>
</template>

<script>
import {
  InfraContentfulRichTextContainer,
  InfraAccordion,
  InfraAccordionItem
} from 'tws-vue-components'

export default {
  components: {
    InfraContentfulRichTextContainer,
    InfraAccordion,
    InfraAccordionItem
  },
  props: {
    content: {
      type: Object,
      required: true
    },
    faq: {
      type: Object,
      default: null
    }
  }
}
</script>
<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container {
  margin-bottom: 48px;
}

.faq {
  position: relative;
  z-index: 0;
  margin-top: 32px;

  @media (min-width: $breakpoint-md) {
    margin-right: 100px;
  }

  &--accordion-item {
    ::v-deep h3  span {
      max-width: 80%;
    }
  }
}
</style>
