<template>
  <div class="container">
    <div
      v-if="loading || exception"
      class="row justify-content-center">
      <div class="col-8">
        <InfraPageAlert
          v-if="loading"
          class="page-alert"
          type="info">
          {{ $t('common.loading') }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="exception"
          class="page-alert"
          type="danger">
          {{ $t('common.loading-failed') }}
        </InfraPageAlert>
      </div>
    </div>

    <template v-else>
      <div
        v-show="companyFilterMessageVisible"
        class="row justify-content-center mb-5">
        <div class="col-8">
          <InfraPageAlert
            class="page-alert"
            type="warning">
            {{ $t('invoice-specifications.company-filter-message.line-1') }}<br>
            {{ $t('invoice-specifications.company-filter-message.line-2') }}
          </InfraPageAlert>
        </div>
      </div>

      <template v-if="items.length > 0">
        <div class="row mb-5">
          <div class="col-lg-4 col-md-12 ">
            <div class="form-control user-search-field">
              <TeliaCoreIcon
                class="search-icon"
                name="search_thin"
                size="2.7rem" />

              <input
                v-model="searchTermProxy"
                type="text"
                class="input-text"
                :placeholder="$t('invoice-specifications.search-placeholder')">
            </div>
          </div>

          <div class="col-lg-8 col-md-12">
            <div class="result-actions">
              <tws-borderless-button
                class="result-action color-core-purple"
                @click="toggleAdditionalFilters">
                {{ showFilters ? $t('common.close-filter') : $t('common.filter') }}

                <tws-arrow-up-icon
                  v-show="showFilters"
                  class="icon" />

                <tws-arrow-down-icon
                  v-show="!showFilters"
                  class="icon" />

                <span :class="{ 'active-filter-list': showFilters }" />
              </tws-borderless-button>

              <tws-borderless-dropdown
                :items="sortingItems"
                :default-key="sortingKey"
                :displayed-value-wrapper="`${$t('common.sort')} ({value})`"
                class="result-action color-core-purple"
                @onChange="onSortChange" />
            </div>
          </div>
        </div>

        <invoice-specification-types-filter
          v-show="showFilters"
          v-model="typeFilterProxy"
          :available-invoice-types="availableInvoiceTypes"
          class="my-5" />

        <div class="row">
          <invoice-specification-table
            v-show="filteredItems.length > 0"
            class="col-12"
            :items="pagedItems"
            :total-count="filteredItems.length"
            :limit="limit"
            :offset="offset"
            @onOffsetChange="changeOffset" />
        </div>
      </template>

      <div
        v-show="filteredItems.length === 0"
        class="row">
        <div class="col-12 no-results-message-column">
          <p class="preamble">
            {{ $t('invoice-specifications.no-data') }}
          </p>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import {
  mapActions,
  mapGetters,
  mapState
} from 'vuex'

import InvoiceSpecificationTable from './invoice-specification-table'
import InvoiceSpecificationTypesFilter from './invoice-specification-types-filter'

import {
  InfraPageAlert,
  TwsBorderlessButton,
  TwsBorderlessDropdown
} from 'tws-vue-components'

import { SORTING_KEY } from '@/store/modules/invoice-specifications.store.js'

export default {
  components: {
    InvoiceSpecificationTable,
    InvoiceSpecificationTypesFilter,
    InfraPageAlert,
    TwsBorderlessButton,
    TwsBorderlessDropdown
  },
  data () {
    return {
      searchFilterModel: 'terms',
      showFilters: false
    }
  },
  computed: {
    sortingItems () {
      return [
        {
          key: SORTING_KEY.DATE_DESC,
          value: this.$t('invoice-specifications.sort-labels.date-descending')
        },
        {
          key: SORTING_KEY.DATE_ASC,
          value: this.$t('invoice-specifications.sort-labels.date-ascending')
        }
      ]
    },
    ...mapState('invoiceSpecifications', [
      'loading',
      'loaded',
      'exception',
      'typeFilter',
      'limit',
      'offset',
      'searchTerm',
      'sortingKey',
      'items'
    ]),
    ...mapState('MyDocuments', ['company']),
    ...mapGetters('invoiceSpecifications', [
      'filteredItems',
      'availableInvoiceTypes'
    ]),
    ...mapGetters('eamCookies', [
      'isSuperUser',
      'isAdmin'
    ]),
    searchTermProxy: {
      get () { return this.searchTerm },
      set (term) { this.changeSearchTerm(term) }
    },
    typeFilterProxy: {
      get () { return this.typeFilter },
      set (typeFilter) { this.changeTypeFilter(typeFilter) }
    },
    pagedItems () {
      return this.filteredItems.slice(this.offset, this.offset + this.limit)
    },
    companyFilterMessageVisible () {
      return (this.isSuperUser || this.isAdmin) && !this.company
    }
  },
  watch: {
    company () {
      this.loadItems()
    }
  },
  mounted () {
    this.loadItems()
  },
  methods: {
    ...mapActions('invoiceSpecifications', [
      'loadItems',
      'changeOffset',
      'changeSearchTerm',
      'changeTypeFilter',
      'changeSorting'
    ]),
    toggleAdditionalFilters () {
      this.showFilters = !this.showFilters
    },
    onSortChange (sortingKey) {
      this.changeSorting(sortingKey)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.invoice-specifications {
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 30px;
  }
}

.container {
  margin: 60px auto 0;
}

.no-results-message-column {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;

  .preamble {
    border: 1px solid $medium-grey;
    padding: 20px 30px;
    width: 790px;
  }
}

.icon {
  margin-left: 0.7rem;
}

.search-icon {
  position: absolute;
  margin-left: 1rem;
  margin-top: 1rem;
}

.user-search-field {
  margin-bottom: 0;

  input {
    background-color: $light-grey;
    border-radius: 50px;
    border-color: $light-grey;
    padding-left: 46px;
  }

  i {
    position: absolute;
    top: 17px;
    left: 18px;
  }
}

.result-actions {
  display: flex;
  justify-content: flex-end;
}

.result-action {
  margin: 10px;
  padding: 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
