import UserUtils from '@/utils/user.utils'
import Constants from '../utils/constants'
import Wow from './wow-mapping'
import i18n from '@/i18n'
import DateUtils from '@/utils/date.utils'

const resolveCaseType = (caseType, product) => {
  if (product === Constants.FAKE_PRODUCTS.SUPPORT_AFTER_WORK_HOURS) {
    return Wow.CASE_TYPE_TO_STRING[Constants.CASE_TYPES.ESCALATION]
  }

  return Wow.CASE_TYPE_TO_STRING[caseType]
}

const resolveProduct = (product) => {
  return Wow.PRODUCT_TO_STRING[product]
}

const resolveContent = (form) => {
  const contentProxy = []
  contentProxy.push(form.content)

  if (form.orderId || form.ttId) {
    contentProxy.push('')
  }

  if (form.orderId) {
    contentProxy.push(`Ordernummer: ${form.orderId}`)
  }

  if (form.ttId) {
    contentProxy.push(`Reklamationsnummer: ${form.ttId}`)
  }

  return contentProxy.join('\n')
}

const resolveContactEmailData = (form) => {
  const responseEmailString = form.responseEmail.join(';')

  return {
    responseEmail: responseEmailString,
    contactEmail: UserUtils.email(),
    contactFirstName: UserUtils.firstName(),
    contactLastName: UserUtils.lastName()
  }
}

export const buildBillingTicketRequest = (form) => {
  return {
    category: i18n.t(form.billingCaseType),
    subCategory: Constants.BILLING_CASE_TYPES_MAPPING[form.billingCaseType] || 'Question',
    customerRefNumber: form.customerReference || '',
    organisationNumber: form.organizationNumber || '',
    productList: form.product ? [form.product] : form.selectedProducts,
    description: form.content || '',
    invoiceNumber: form.invoiceNumber || '',
    watchlist: form.responseEmail.map(email => email.trim()).filter(email => email),
    attachments: form.files
  }
}

export const buildTicketCreateRequest = (form) => {
  return {
    caseType: resolveCaseType(form.caseType, form.product),
    product: resolveProduct(form.product),
    subject: form.subject,
    content: resolveContent(form),
    ...form.assetId && { assetId: form.assetId },
    ...form.customerReference && { customerReference: form.customerReference },
    ...form.orderId && { orderId: form.orderId },
    ...form.ttId && { ttId: form.ttId },
    ...resolveContactEmailData(form)
  }
}

export const mapBillingCaseToTicket = (caseItem) => {
  return {
    supportId: caseItem.caseId,
    caseType: '',
    subject: caseItem.shortDescription,
    account: '',
    status: caseItem.status,
    created: '',
    responseRequired: '',
    product: '',
    createdByUser: caseItem.createdByUser,
    createdByUserMail: '',
    lastUpdated: DateUtils.fullDateTime(caseItem.updatedDate),
    createdByUserInfo: null,
    queue: '',
    customerReference: caseItem.customerReference
  }
}
