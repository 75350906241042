import TicketService from '../service/ticket.service'
import { createBillingCase } from '@/apps/fault-reporting/services/teliaNOW.service'

export default {
  namespaced: true,
  state: {
    response: null,
    loading: false,
    exception: false
  },
  mutations: {
    setResponse: (state, response) => state.response = response,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception
  },
  actions: {
    async postRequest ({ commit }, payload) {
      try {
        commit('setLoading', true)
        commit('setException', false)

        const response = await TicketService.createTicket(payload.ticketCreateRequest)

        if (response['ERROR']) {
          commit('setException', true)
        }

        await Promise.all(
          payload.files.map(
            file => TicketService.putAttachment(response.supportId, file.file)
          )
        )

        await TicketService.activateTicket(response.supportId)

        commit('setResponse', response)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    },
    async postRequestBilling ({ commit }, payload) {
      try {
        commit('setLoading', true)
        commit('setException', false)

        const response = await createBillingCase(payload.ticketCreateRequest)

        commit('setResponse', response)
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  getters: {
    response: ({ response }) => response,
    loading: ({ loading }) => loading,
    exception: ({ exception }) => exception
  }
}
