<template>
  <div class="ticket-details-billing-view">
    <div v-if="loading || exception">
      <GrootTicketDetailsSkeletonLoader
        v-if="loading"
        :language="locale" />

      <GrootErrorPage
        v-if="exception"
        :language="locale"
        :link="{
          href: '/tickets/list',
          text: $t('ticket-details.go-back-to-list'),
        }" />
    </div>
    <div v-else>
      <GrootTicketDetailsHeroBanner
        :title="response.name"
        :subject="$t('ticket-list.table.case-number') + ': ' + response.caseId"
        :link="{
          href: '/tickets/list',
          text: $t('ticket-details.go-back-to-list'),
        }" />

      <GrootTicketDetailsBilling
        :language="locale"
        :status="response.status"
        :description="response.description"
        :name="response.name"
        :caseId="response.caseId"
        :updatedDate="response.updatedDate"
        :customerReference="response.customerReference"
        :watchlist="response.watchlist"
        :attachments="response.attachments">
        <GrootConversationBlock
          :language="locale"
          :notes="convertedActivityLog"
          :loading="notesLoading || attachmentsLoading"
          :exception="notesException"
          :attachmentsException="attachmentsException"
          :submit="submit"
          type="TELIA_NOW" />
      </GrootTicketDetailsBilling>
    </div>
  </div>
</template>

<script>
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import {
  GrootConversationBlock,
  GrootErrorPage,
  GrootTicketDetailsBilling,
  GrootTicketDetailsHeroBanner,
  GrootTicketDetailsSkeletonLoader
} from 'groot-components'

import {
  mapActions, mapState
} from 'vuex'

export default {
  components: {
    GrootTicketDetailsHeroBanner: applyReactInVueWithOptions(
      GrootTicketDetailsHeroBanner
    ),
    GrootTicketDetailsBilling: applyReactInVueWithOptions(
      GrootTicketDetailsBilling
    ),
    GrootErrorPage: applyReactInVueWithOptions(GrootErrorPage),
    GrootTicketDetailsSkeletonLoader: applyReactInVueWithOptions(
      GrootTicketDetailsSkeletonLoader
    ),
    GrootConversationBlock: applyReactInVueWithOptions(GrootConversationBlock)
  },
  data () {
    return {
      locale: this.$route.params?.locale
    }
  },
  computed: {
    ...mapState('CustomerTickets/TicketDetailsBilling', [
      'response',
      'loading',
      'exception',
      'attachmentsLoading',
      'attachmentsException'
    ]),
    ...mapState('CustomerTickets/TicketNotesBilling', [
      'notesLoading',
      'notesException'
    ]),
    convertedActivityLog () {
      const notes = this.response.activityLog

      return notes.map((item) => {
        const { date, text, author, ...rest } = item
        return {
          ...rest,
          created: date,
          content: text,
          createdBy: author
        }
      })
    }
  },
  async mounted () {
    const id = this.$route.params.supportId
    await this.fetchDetails(id)
  },
  methods: {
    ...mapActions('CustomerTickets/TicketDetailsBilling', [
      'fetchDetails',
      'putAttachments'
    ]),
    ...mapActions('CustomerTickets/TicketNotesBilling', ['postComment']),
    async submit (form) {

      if (form.message?.length > 0) {
        await this.postComment({
          id: this.response.caseId,
          request: {
            text: form.message
          }
        })
      }

      if (form.files?.length > 0) {
        await this.putAttachments(form.files)
      }
    }
  }
}
</script>
