import ProductManagementService from '@/services/product-management.service'

const PRODUCT_CATEGORY_NAMES = {
  FIBER: 'product-categories.fiber',
  KOPPAR: 'product-categories.copper',
  TJANSTER: 'product-categories.services',
  KAPACITET: 'product-categories.ethernet',
  MOBILE: 'product-categories.mobile',
  MOBILE_WHOLESALE: 'product-categories.mobile-wholesale',
  ESTABLISHMENT: 'product-categories.establishment',
  DIGITAL_TOOLS: 'product-categories.digital-tools'
}

const FIBER_PRODUCT_NAMES = {
  TELIA_WHOLESALE_FIBER: 'product-names.wholesale-fiber',
  SKANOVA_FIBER_2009: 'product-names.fiber-2009',
  SKANOVA_FIBER_SINGEL: 'product-names.fiber-single',
  FIBER_VILLA: 'product-names.fiber-villa',
  FIBER_LAGENHET: 'product-names.fiber-apartment',
  FIBER_SMAFORETAG: 'product-names.fiber-small-business',
  OVRIGA_FIBERPRODUKTER: 'product-names.fiber-other',
  COMOP_ZITIUS: 'product-names.zitius'
}

const COPPER_PRODUCT_NAMES = {
  KOPPARACCESS: 'product-names.copper-access',
  KOPPAR_PUNKT_TILL_PUNKT: 'product-names.copper-point-to-point',
  IP_STREAM_ENHANCED: 'product-names.ip-enhanced',
  IP_STREAM_ENHANCED_IPSE: 'product-names.ip-enhanced-ipse',
  TELEFONIABONNEMANG: 'product-names.phone-subscription'
}

const SERVICES_PRODUCT_NAMES = {
  OPERATORSBYTE: 'product-names.operator-change',
  ADDRESS_CONNECTION: 'product-names.address-connection',
  ADDRESS_REQUEST: 'product-names.address-request',
  EXPRESS_DELIVERY: 'product-names.express-delivery',
  BLOCK_REQUEST: 'product-names.block-request',
  MAP_MATERIAL: 'product-names.map-material',
  MAP_SUBSCRIPTION: 'product-names.map-subscription',
  ESTABLISHMENT_DISCONNECT: 'product-names.establishment-disconnect',
  APARTMENT_MAINS: 'product-names.apartment-mains',
  TELIA_WHOLESALE_INTERNAL_NODE: 'product-names.telia-wholesale-node-internal',
  FIBER_PERFORMANCE: 'product-names.fiber-performance'
}

const ETHERNET_PRODUCT_NAMES = {
  ETHERNET_SWEDEN: 'product-names.ethernet-sweden',
  ETHERNET_SWEDEN_TSES: 'product-names.ethernet-sweden-tses',
  ETHERNET_NORDIC_TSEN: 'product-names.ethernet-nordic-tsen',
  SDH_SWEDEN: 'product-names.sdh-sweden',
  SDH_SWEDEN_BACKBONE: 'product-names.sdh-sweden-backbone',
  CAPACITY_BASIC: 'product-names.capacity-basic',
  CAPACITY_WHOLESALE: 'product-names.capacity-wholesale',
  MOBILE_BACKHAULT: 'product-names.mobile-backhaul',
  WAVELENGTH: 'product-names.product-names',
  WAVELENGTH_WDM: 'product-names.wavelength-wdm',
  IP_TRANSIT: 'product-names.ip-transit',
  TELIA_WHOLESALE_CAPACITY: 'product-names.telia-wholesale-capacity'
}

const MOBILE_PRODUCT_NAMES = {
  MOBILE_HOST: 'product-names.mobile-host',
  PORTABILITY: 'product-names.portability',
  CREATE_ERROR_REPORT: 'product-names.create-error-report'
}

const TICKET_PRODUCT_NAMES = {
  INTERCONNECTION_MOBILE: 'product-names.interconnection-mobile',
  FIXED_TICKETS: 'product-names.fixed-tickets',
  INTERCONNECTION_FAST: 'product-names.interconnection-fast',
  NUMBER_PORTABILITY_FAST: 'product-names.number-portability-fast',
  CONNECTED_TRANSIT: 'product-names.connected-transit'
}

const ESTABLISHMENT_PRODUCT_NAMES = {
  LOCATION: 'product-names.location',
  CO_LOCATION: 'product-names.co-location',
  ANTENNA_LOCATION: 'product-names.antenna-location',
  OPERATOR_NODE: 'product-names.operator-node',
  EXTERNAL_COPPER: 'product-names.external-copper',
  OPERATOR_TRUNK: 'product-names.operator-trunk',
  STATION_CABLE: 'product-names.station-cable'
}

const DIGITAL_TOOLS_PRODUCT_NAMES = {
  CUSTOMER_PORTAL: 'product-names.customer-portal',
  NET_BUSINESS_CONNEECT: 'product-names.net-business-connect'
}

const COMMON = {
  PRODUCTS: 'product-names.products',
  PHONE_SUBSCRIPTION: 'product-names.phone-subscription',
  TROUBLESHOOTING: 'product-names.troubleshooting'
}

const CATEGORIES = {
  FIBER: {
    title: PRODUCT_CATEGORY_NAMES.FIBER,
    categories: {
      'WHOLESALE_FIBER': {
        index: 0,
        title: FIBER_PRODUCT_NAMES.TELIA_WHOLESALE_FIBER,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_WHOLESALE_FIBER_PATH,
          process.env.VUE_APP_IFRAME_WHOLESALE_FIBER
        ),
        external: false
      },
      'SKANOVA_FIBER_2009': {
        index: 1,
        title: FIBER_PRODUCT_NAMES.SKANOVA_FIBER_2009,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_FIBER_2009_PATH,
          process.env.VUE_APP_IFRAME_SKANOVA_FIBER_2009
        ),
        external: false
      },
      'SKANOVA_FIBER_SINGEL': {
        index: 2,
        title: FIBER_PRODUCT_NAMES.SKANOVA_FIBER_SINGEL,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_FIBER_SINGEL_PATH,
          process.env.VUE_APP_IFRAME_SKANOVA_FIBER_SINGEL
        ),
        external: false
      },
      'FIBER_VILLA': {
        index: 3,
        title: FIBER_PRODUCT_NAMES.FIBER_VILLA,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_FIBER_VILLA_PATH,
          process.env.VUE_APP_IFRAME_FIBER_VILLA
        ),
        external: false
      },
      'FIBER_LAGENHET': {
        index: 4,
        title: FIBER_PRODUCT_NAMES.FIBER_LAGENHET,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_FIBER_LAGENHET_PATH,
          process.env.VUE_APP_IFRAME_FIBER_LAGENHET
        ),
        external: false
      },
      'FIBER_SMAFORETAG': {
        index: 5,
        title: FIBER_PRODUCT_NAMES.FIBER_SMAFORETAG,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_FIBER_SMAFORETAG_PATH,
          process.env.VUE_APP_IFRAME_FIBER_SMAFORETAG
        ),
        external: false
      }
    },
    expandable: true,
    expanded: false
  },
  KOPPAR: {
    title: PRODUCT_CATEGORY_NAMES.KOPPAR,
    categories: {
      'KOPPARACCESS': {
        index: 0,
        title: COPPER_PRODUCT_NAMES.KOPPARACCESS,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_KOPPARACCESS_PATH,
          process.env.VUE_APP_IFRAME_KOPPARACCESS
        ),
        external: false
      },
      'KOPPAR_PUNKT_TILL_PUNKT': {
        index: 1,
        title: COPPER_PRODUCT_NAMES.KOPPAR_PUNKT_TILL_PUNKT,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_KOPPAR_PUNKT_TILL_PUNKT_PATH,
          process.env.VUE_APP_IFRAME_KOPPAR_PUNKT_TILL_PUNKT
        ),
        external: false
      },
      'IP_STREAM_ENHANCED': {
        index: 2,
        title: COPPER_PRODUCT_NAMES.IP_STREAM_ENHANCED,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ESALES_TOB_IP_STREAM_ENHANCED,
          process.env.VUE_APP_IFRAME_ESALES_TOB_IP_STREAM_ENHANCED
        ),
        external: false
      },
      'TELEFONIABONNEMANG': {
        index: 3,
        title: COPPER_PRODUCT_NAMES.TELEFONIABONNEMANG,
        link: process.env.VUE_APP_IFRAME_TELEFONIABONNEMAG,
        external: true
      }
    },
    expandable: true,
    expanded: false
  },
  TJANSTER: {
    title: PRODUCT_CATEGORY_NAMES.TJANSTER,
    categories: {
      'OPERATORSBYTE': {
        index: 0,
        title: SERVICES_PRODUCT_NAMES.OPERATORSBYTE,
        link: process.env.VUE_APP_OPERATORSBYTE_PATH,
        external: false
      }
    },
    expandable: true,
    expanded: false
  },
  IP_WDM: {
    title: PRODUCT_CATEGORY_NAMES.KAPACITET,
    categories: {
      'CAPACITY_WHOLESALE': {
        index: 0,
        title: ETHERNET_PRODUCT_NAMES.CAPACITY_WHOLESALE,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ESALES_TOB_CAPACITY_WHOLESALE,
          process.env.VUE_APP_IFRAME_ESALES_TOB_CAPACITY_WHOLESALE
        ),
        external: false
      },
      'ETHERNET_SWEDEN': {
        title: ETHERNET_PRODUCT_NAMES.ETHERNET_SWEDEN,
        index: 1,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ESALES_TOB_ETHERNET_SWEDEN,
          process.env.VUE_APP_IFRAME_ESALES_TOB_ETHERNET_SWEDEN
        ),
        external: false
      },
      'SDH_SWEDEN': {
        index: 2,
        title: ETHERNET_PRODUCT_NAMES.SDH_SWEDEN,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ESALES_TOB_SDH_SWEDEN,
          process.env.VUE_APP_IFRAME_ESALES_TOB_SDH_SWEDEN
        ),
        external: false
      },
      'CAPACITY_BASIC': {
        index: 3,
        title: ETHERNET_PRODUCT_NAMES.CAPACITY_BASIC,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ESALES_TOB_CAPACITY_BASIC,
          process.env.VUE_APP_IFRAME_ESALES_TOB_CAPACITY_BASIC
        ),
        external: false
      },
      'MOBILE_BACKHAULT': {
        index: 4,
        title: ETHERNET_PRODUCT_NAMES.MOBILE_BACKHAULT,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ESALES_TOB_4G_MOBILE_BACKHAUL,
          process.env.VUE_APP_IFRAME_ESALES_TOB_4G_MOBILE_BACKHAUL
        ),
        external: false
      },
      'WAVELENGTH': {
        index: 5,
        title: ETHERNET_PRODUCT_NAMES.WAVELENGTH,
        link: process.env.VUE_APP_FB_ESALES_TOB_WAVELENGTH,
        external: false
      }
    },
    expandable: true,
    expanded: false
  }
}

const MENU_ITEMS = {
  CATEGORIES: () => {
    return CATEGORIES
  },
  MOBILE_WHOLESALE_PRODUCTS: () => {
    return {
      title: PRODUCT_CATEGORY_NAMES.MOBILE_WHOLESALE,
      categories: [{
        index: 0,
        title: MOBILE_PRODUCT_NAMES.CREATE_ERROR_REPORT,
        link: process.env.VUE_APP_IFRAME_TICKETS_MOBILE_WHOLESALE_PRODUCTS,
        external: true
      }],
      expandable: true,
      expanded: false
    }
  },
  MY_ORDERS: () => {
    return {
      skanovaProducts: {
        index: 0,
        title: COMMON.PRODUCTS,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_HANTERA_PATH,
          process.env.VUE_APP_IFRAME_SKANOVA_PRODUCTS
        )
      },
      telefoniabonnemang: {
        index: 1,
        title: COMMON.PHONE_SUBSCRIPTION,
        link: process.env.VUE_APP_IFRAME_TELEFONIABONNEMAG,
        external: true
      }
    }
  },
  TJANSTER_PRODUCTS: () => {
    return [
      {
        index: 0,
        title: SERVICES_PRODUCT_NAMES.ADDRESS_CONNECTION,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_ADDRESSANSLUTNING_KOMPLETTERING_PATH,
          process.env.VUE_APP_IFRAME_ADDRESSANSLUTNING_KOMPLETTERING
        ),
        external: false
      },
      {
        index: 2,
        title: SERVICES_PRODUCT_NAMES.ADDRESS_REQUEST,
        link: process.env.VUE_APP_ADDRESS_REQUEST_PATH,
        external: false
      },
      {
        index: 3,
        title: SERVICES_PRODUCT_NAMES.EXPRESS_DELIVERY,
        link: process.env.VUE_APP_EXPRESS_DELIVERY_PATH,
        external: false
      },
      {
        index: 4,
        title: SERVICES_PRODUCT_NAMES.BLOCK_REQUEST,
        link: process.env.VUE_APP_BLOCKING_INQUIRY_PATH,
        external: false
      },
      {
        index: 5,
        title: SERVICES_PRODUCT_NAMES.MAP_MATERIAL,
        link: process.env.VUE_APP_MAP_MATERIAL_PATH,
        external: false
      },
      {
        index: 6,
        title: SERVICES_PRODUCT_NAMES.MAP_SUBSCRIPTION,
        link: process.env.VUE_APP_SUBSCRIPTION_FOR_MAP_FILES_PATH,
        external: false
      },
      {
        index: 7,
        title: SERVICES_PRODUCT_NAMES.ESTABLISHMENT_DISCONNECT,
        link: process.env.VUE_APP_ESTABLISHMENT_DISCONNECT_PATH,
        external: false
      },
      {
        index: 8,
        title: SERVICES_PRODUCT_NAMES.TELIA_WHOLESALE_INTERNAL_NODE,
        link: process.env.VUE_APP_TELIA_WHOLESALE_INTERNAL_NODE_PATH,
        external: false
      },
      {
        index: 9,
        title: SERVICES_PRODUCT_NAMES.FIBER_PERFORMANCE,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FB_FIBER_PERFORMANCE_PATH,
          process.env.VUE_APP_IFRAME_FIBER_PERFORMANCE
        ),
        external: false
      }
    ]
  },
  TJANSTER_ADDITIONAL_PRODUCTS: () => {
    return [{
      index: 1,
      title: SERVICES_PRODUCT_NAMES.APARTMENT_MAINS,
      link: process.env.VUE_APP_FIBER_LAGENHET_NATANSLUTNING_PATH,
      external: false
    }]
  },
  FIBER_TICKETS: () => {
    return {
      title: PRODUCT_CATEGORY_NAMES.FIBER,
      categories: []
    }
  },
  FIBER_TICKETS_SKANOVA_ROLE: (username) => {
    return [
      {
        index: 1,
        title: FIBER_PRODUCT_NAMES.SKANOVA_FIBER_2009,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FELANMALAN_PATH,
          process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
        ),
        external: false
      },
      {
        index: 2,
        title: FIBER_PRODUCT_NAMES.SKANOVA_FIBER_SINGEL,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FELANMALAN_PATH,
          process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
        ),
        external: false
      },
      {
        index: 3,
        title: FIBER_PRODUCT_NAMES.FIBER_LAGENHET,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FELANMALAN_PATH,
          process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
        ),
        external: false
      },
      {
        index: 4,
        title: FIBER_PRODUCT_NAMES.FIBER_VILLA,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FELANMALAN_PATH,
          process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
        ),
        external: false
      },
      {
        index: 5,
        title: FIBER_PRODUCT_NAMES.FIBER_SMAFORETAG,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FELANMALAN_PATH,
          process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
        ),
        external: false
      },
      {
        index: 6,
        title: FIBER_PRODUCT_NAMES.OVRIGA_FIBERPRODUKTER,
        link: ProductManagementService.getIframePath(
          process.env.VUE_APP_FELANMALAN_PATH,
          process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
        ),
        external: false
      },
      {
        index: 7,
        title: FIBER_PRODUCT_NAMES.COMOP_ZITIUS,
        link: process.env.VUE_APP_ZITIUS,
        external: true
      },
      {
        index: 8,
        title: COMMON.TROUBLESHOOTING,
        link: process.env.VUE_APP_IFRAME_TICKETS_LIST_TOB_TICKETS,
        external: true
      }
    ]
  },
  TELIA_WHOLESALE_FIBER_TICKETS: (username) => {
    return {
      index: 0,
      title: FIBER_PRODUCT_NAMES.TELIA_WHOLESALE_FIBER,
      link: ProductManagementService.getIframePath(
        process.env.VUE_APP_FELANMALAN_PATH,
        process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
      ),
      external: false
    }
  },
  MOBILE_TICKETS: () => {
    return {
      title: PRODUCT_CATEGORY_NAMES.MOBILE,
      categories: [
        {
          index: 0,
          title: MOBILE_PRODUCT_NAMES.MOBILE_HOST,
          link: process.env.VUE_APP_IFRAME_TICKETS_MOBILE_WHOLESALE_PRODUCTS,
          external: true
        },
        {
          index: 2,
          title: MOBILE_PRODUCT_NAMES.PORTABILITY,
          link: process.env.VUE_APP_IFRAME_TICKETS_MOBILE_WHOLESALE_PRODUCTS,
          external: true
        }
      ]
    }
  },
  MOBILE_TICKETS_SKANOVA: () => {
    return {
      index: 1,
      title: TICKET_PRODUCT_NAMES.INTERCONNECTION_MOBILE,
      link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
      external: true
    }
  },
  MOBILE_TICKETS_ADDITIONAL: () => {
    return {
      index: 3,
      title: COMMON.TROUBLESHOOTING,
      link: process.env.VUE_APP_IFRAME_TICKETS_LIST_TOB_TICKETS,
      external: true
    }
  },
  CAPACITY_TICKETS: () => {
    return {
      title: PRODUCT_CATEGORY_NAMES.KAPACITET,
      categories: [
        {
          index: 0,
          title: ETHERNET_PRODUCT_NAMES.TELIA_WHOLESALE_CAPACITY,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 1,
          title: ETHERNET_PRODUCT_NAMES.CAPACITY_BASIC,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 2,
          title: ETHERNET_PRODUCT_NAMES.ETHERNET_SWEDEN_TSES,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 3,
          title: ETHERNET_PRODUCT_NAMES.ETHERNET_NORDIC_TSEN,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 4,
          title: ETHERNET_PRODUCT_NAMES.SDH_SWEDEN_BACKBONE,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 5,
          title: ETHERNET_PRODUCT_NAMES.WAVELENGTH_WDM,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 6,
          title: ETHERNET_PRODUCT_NAMES.IP_TRANSIT,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 7,
          title: COMMON.TROUBLESHOOTING,
          link: process.env.VUE_APP_IFRAME_TICKETS_LIST_TOB_TICKETS,
          external: true
        }
      ]
    }
  },
  FIXED_TICKETS: () => {
    return {
      title: TICKET_PRODUCT_NAMES.FIXED_TICKETS,
      categories: []
    }
  },
  FIXED_TICKETS_TELEFONIABONNEMANG: () => {
    return {
      index: 0,
      title: COMMON.PHONE_SUBSCRIPTION,
      link: process.env.VUE_APP_IFRAME_TELEFONIABONNEMAG_FELANMALAN,
      external: true
    }
  },
  FIXED_TICKETS_SKANOVA: () => {
    return [
      {
        index: 1,
        title: TICKET_PRODUCT_NAMES.INTERCONNECTION_FAST,
        link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
        external: true
      },
      {
        index: 2,
        title: TICKET_PRODUCT_NAMES.NUMBER_PORTABILITY_FAST,
        link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
        external: true
      },
      {
        index: 3,
        title: TICKET_PRODUCT_NAMES.CONNECTED_TRANSIT,
        link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
        external: true
      }
    ]
  },
  FIXED_TICKETS_ADDITIONAL: () => {
    return {
      index: 4,
      title: COMMON.TROUBLESHOOTING,
      link: process.env.VUE_APP_IFRAME_TICKETS_LIST_TOB_TICKETS,
      external: true
    }
  },
  KOPPAR_TICKETS: (username) => {
    return {
      title: PRODUCT_CATEGORY_NAMES.KOPPAR,
      categories: [
        {
          index: 0,
          title: COPPER_PRODUCT_NAMES.KOPPARACCESS,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 1,
          title: COPPER_PRODUCT_NAMES.KOPPAR_PUNKT_TILL_PUNKT,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 2,
          title: COPPER_PRODUCT_NAMES.IP_STREAM_ENHANCED_IPSE,
          link: process.env.VUE_APP_OPERATOR_PORTAL_PATH,
          external: true
        },
        {
          index: 3,
          title: COMMON.TROUBLESHOOTING,
          link: process.env.VUE_APP_IFRAME_TICKETS_LIST_TOB_TICKETS,
          external: true
        }
      ]
    }
  },
  ESTABLISHMENT_TICKETS: (username) => {
    return {
      title: PRODUCT_CATEGORY_NAMES.ESTABLISHMENT,
      categories: [
        {
          index: 0,
          title: ESTABLISHMENT_PRODUCT_NAMES.LOCATION,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 1,
          title: ESTABLISHMENT_PRODUCT_NAMES.OPERATOR_TRUNK,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 2,
          title: ESTABLISHMENT_PRODUCT_NAMES.OPERATOR_NODE,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 3,
          title: ESTABLISHMENT_PRODUCT_NAMES.STATION_CABLE,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 4,
          title: ESTABLISHMENT_PRODUCT_NAMES.CO_LOCATION,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 5,
          title: ESTABLISHMENT_PRODUCT_NAMES.EXTERNAL_COPPER,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        },
        {
          index: 6,
          title: ESTABLISHMENT_PRODUCT_NAMES.ANTENNA_LOCATION,
          link: ProductManagementService.getIframePath(
            process.env.VUE_APP_FELANMALAN_PATH,
            process.env.VUE_APP_IFRAME_TICKETS_CREATE_SKANOVA_TICKET + username
          ),
          external: false
        }
      ]
    }
  },
  DIGITAL_TOOLS_TICKETS: () => {
    return {
      title: PRODUCT_CATEGORY_NAMES.DIGITAL_TOOLS,
      categories: [
        {
          index: 0,
          title: DIGITAL_TOOLS_PRODUCT_NAMES.CUSTOMER_PORTAL,
          link: process.env.VUE_APP_CONTACT_PORTAL_FORM,
          external: false
        },
        {
          index: 1,
          title: DIGITAL_TOOLS_PRODUCT_NAMES.NET_BUSINESS_CONNEECT,
          link: 'mailto:' + process.env.VUE_APP_NET_BUSINESS_CONNECT_EMAIL,
          external: true
        }
      ]
    }
  }
}

const collectFixedTickets = (skanovaRoles, telefoniabonnemangRoles) => {
  const fixedTickets = MENU_ITEMS.FIXED_TICKETS()

  if (skanovaRoles) {
    fixedTickets.categories.push(...MENU_ITEMS.FIXED_TICKETS_SKANOVA())
  }

  if (telefoniabonnemangRoles) {
    fixedTickets.categories.push(MENU_ITEMS.FIXED_TICKETS_TELEFONIABONNEMANG())
  }

  if (skanovaRoles || telefoniabonnemangRoles) {
    fixedTickets.categories.push(MENU_ITEMS.FIXED_TICKETS_ADDITIONAL())
  }

  return fixedTickets
}

const collectMobileTickets = (skanovaRoles) => {
  const mobileTickets = MENU_ITEMS.MOBILE_TICKETS()

  if (skanovaRoles) {
    mobileTickets.categories.push(MENU_ITEMS.MOBILE_TICKETS_SKANOVA())
  }

  mobileTickets.categories.push(MENU_ITEMS.MOBILE_TICKETS_ADDITIONAL())

  return mobileTickets
}

const collectFiberTickets = (skanovaRoles, username, productsWithAgreement) => {
  const fiberTickets = MENU_ITEMS.FIBER_TICKETS()

  if (productsWithAgreement) {
    fiberTickets.categories.push(MENU_ITEMS.TELIA_WHOLESALE_FIBER_TICKETS())
  }

  if (skanovaRoles) {
    fiberTickets.categories.push(...MENU_ITEMS.FIBER_TICKETS_SKANOVA_ROLE(username))
  }

  return fiberTickets
}

export default {
  MENU_ITEMS,
  PRODUCT_CATEGORY_NAMES,
  FIBER_PRODUCT_NAMES,
  COPPER_PRODUCT_NAMES,
  ETHERNET_PRODUCT_NAMES,
  MOBILE_PRODUCT_NAMES,
  SERVICES_PRODUCT_NAMES,
  COMMON,

  collectFixedTickets,
  collectMobileTickets,
  collectFiberTickets
}
