var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ticket-details-wow-view"},[(_vm.loading || _vm.exception)?_c('div',[(_vm.loading)?_c('GrootTicketDetailsSkeletonLoader',{attrs:{"language":_vm.locale}}):_vm._e(),(_vm.exception)?_c('GrootErrorPage',{attrs:{"language":_vm.locale,"link":{
        href: '/tickets/list',
        text: _vm.$t('ticket-details.go-back-to-list'),
      }}}):_vm._e()],1):_c('div',[_c('GrootTicketDetailsHeroBanner',{attrs:{"title":_vm.response.subject,"subject":_vm.$t('ticket-list.table.case-number') + ': ' + _vm.response.supportId,"link":{
        href: '/tickets/list',
        text: _vm.$t('ticket-details.go-back-to-list'),
      }}}),_c('GrootTicketDetailsWOW',{attrs:{"language":_vm.locale,"status":_vm.response.status,"supportId":_vm.response.supportId,"caseType":_vm.response.caseType,"product":_vm.response.product,"lastUpdated":_vm.response.lastUpdated,"customerReference":_vm.response.customerReference,"orderId":_vm.response.orderId,"assetId":_vm.response.assetId,"responseEmail":_vm.response.responseEmail,"createdByUserInfo":_vm.response.createdByUserInfo,"ttId":_vm.response.ttId,"subject":_vm.response.subject,"content":_vm.response.content,"notes":_vm.response.notes,"attachments":_vm.response.attachments}},[_c('GrootConversationBlock',{attrs:{"language":_vm.locale,"type":"WOW","notes":_vm.response.notes,"userInfo":_vm.response.createdByUserInfo,"loading":_vm.notesLoading || _vm.attachmentsLoading,"exception":_vm.notesException,"attachmentsException":_vm.attachmentsException,"submit":_vm.submit}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }