<template>
  <Component :is="component" />
</template>
<script>
import TicketDetailsBilling from '@/apps/customer-tickets/views/TicketDetailsBilling'
import TicketDetailsWOW from '@/apps/customer-tickets/views/TicketDetailsWOW'
export default {
  components: {
    TicketDetailsBilling,
    TicketDetailsWOW
  },
  data () {
    return {
      component: null
    }
  },
  created () {
    this.$route.params.supportId.startsWith('C') ?
      this.component = TicketDetailsBilling :
      this.component = TicketDetailsWOW
  }
}
</script>
