import { postComment } from '@/apps/customer-tickets/service/ticket-billing.service'

export default {
  namespaced: true,
  state: {
    notesLoading: false,
    notesException: false
  },
  mutations: {
    setNotesLoading: (state, notesLoading) => state.notesLoading = notesLoading,
    setNotesException: (state, notesException) => state.notesException = notesException
  },
  actions: {
    async postComment ({ commit, dispatch }, payload) {
      try {
        commit('setNotesLoading', true)
        commit('setNotesException', false)

        const response = await postComment(payload.id, payload.request)

        if (!response) {
          throw new Error('Failed to post note')
        }

        dispatch('CustomerTickets/TicketDetailsBilling/addNote', payload.request.text, { root: true })

      } catch {
        commit('setNotesException', true)
      } finally {
        commit('setNotesLoading', false)
      }
    },
    resetState ({ commit }) {
      commit('setNotesLoading', false)
      commit('setNotesException', false)
    }
  }
}
