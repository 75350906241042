import TicketService from '@/apps/customer-tickets/service/ticket.service'

export default {
  namespaced: true,
  state: {
    notesLoading: false,
    loaded: false,
    notesException: false
  },
  mutations: {
    setNotesLoading: (state, notesLoading) => state.notesLoading = notesLoading,
    setLoaded: (state, loaded) => state.loaded = loaded,
    setNotesException: (state, notesException) => state.notesException = notesException
  },
  actions: {
    async postNote ({ commit, dispatch }, payload) {
      try {
        commit('setNotesLoading', true)
        commit('setLoaded', false)
        commit('setNotesException', false)

        const response = await TicketService.postNote(payload.supportId, payload.request)

        if (!response) {
          throw new Error('Failed to post note')
        }

        dispatch('CustomerTickets/TicketDetails/addNote', payload.request.content, { root: true })

        commit('setLoaded', true)
      } catch {
        commit('setNotesException', true)
      } finally {
        commit('setNotesLoading', false)
      }
    },
    resetState ({ commit }) {
      commit('setNotesLoading', false)
      commit('setLoaded', false)
      commit('setNotesException', false)
    }
  }
}
