<template>
  <div class="container">
    <div
      v-if="redirectAction.action && redirectAction.username && redirectMessage"
      class="row redirect-action-row">
      <div class="col">
        <InfraPageAlert
          :type="isUserPartiallyCreated ? 'warning' : 'success'">
          {{ redirectMessage }}
        </InfraPageAlert>
      </div>
    </div>

    <div
      v-if="loaded"
      class="row actions-row">
      <div class="col-lg-4 col-md-12">
        <div class="form-control user-search-field">
          <TeliaCoreIcon
            class="search-icon"
            name="search_thin"
            size="2.7rem" />
          <input
            v-model="searchFilterModel"
            type="text"
            class="input-text"
            :placeholder="$t('user-administration.search-placeholder')">
        </div>
      </div>

      <div class="col-lg-8 col-md-12">
        <div class="result-actions">
          <tws-borderless-custom-content-button class="result-action color-core-purple">
            <template #toggler>
              <span>{{ $t('user-administration.export') }}</span>&nbsp;
              <tws-download-icon />
            </template>

            <span>{{ $t('user-administration.select-format') }}</span>

            <div class="export-buttons">
              <button
                class="export-button"
                @click="exportResults('xlsx')">
                <tws-xlsx-icon />
                <br>
                <span>.xlsx</span>
              </button>

              <button
                class="export-button"
                @click="exportResults('csv')">
                <tws-csv-icon />
                <br>
                <span>.csv</span>
              </button>
            </div>
          </tws-borderless-custom-content-button>

          <tws-borderless-button
            class="result-action color-core-purple"
            @click="toggleAdditionalFilters">
            {{ showFilters ? $t('common.close-filter') : $t('common.filter') }}

            <tws-arrow-up-icon
              v-if="showFilters"
              class="icon" />

            <tws-arrow-down-icon
              v-if="!showFilters"
              class="icon" />

            <span :class="{ 'active-filter-list': showFilters }" />
          </tws-borderless-button>

          <tws-borderless-dropdown
            :items="sortingItems"
            :default-key="sortBy"
            :displayed-value-wrapper="`${$t('common.sort')} ({value})`"
            class="result-action color-core-purple"
            @onChange="onSortChange" />
        </div>
      </div>
    </div>

    <div
      v-show="showFilters"
      class="filters-wrapper">
      <div
        v-if="isSuperUserOrAdmin"
        class="row">
        <div class="col-12">
          <tws-checkbox
            id="system-user-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.SYSTEM_USER)"
            checked-value="SYSTEM_USER" />
        </div>
      </div>

      <div class="row grouped-form-controls">
        <div class="col-lg-3 col-sm-6">
          <label class="label">{{ $t('user-administration.roles') }}</label>

          <tws-checkbox
            id="agreement-responsible-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.AGREEMENT_RESPONSIBLE)"
            :disabled="!isSuperUser && !isAdmin"
            checked-value="AGREEMENT_RESPONSIBLE" />

          <tws-checkbox
            id="reports-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.REPORTS)"
            checked-value="REPORTS" />

          <tws-checkbox
            id="search-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.SEARCH)"
            checked-value="SEARCH" />

          <tws-checkbox
            v-if="isSuperUserOrAdmin"
            id="invoices-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.INVOICES)"
            checked-value="INVOICES" />

          <tws-checkbox
            v-if="isSuperUserOrAdmin"
            id="specifications-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.SPECIFICATIONS)"
            checked-value="SPECIFICATIONS" />
        </div>

        <div class="col-lg-3 col-sm-6">
          <label class="label">{{ $t('user-administration.complaints') }}</label>

          <tws-checkbox
            id="trouble-ticket-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.TROUBLE_TICKET)"
            checked-value="TROUBLE_TICKET" />

          <tws-checkbox
            id="trouble-ticket-plus-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.TROUBLE_TICKET_PLUS)"
            checked-value="TROUBLE_TICKET_PLUS" />

          <tws-checkbox
            id="trouble-ticket-no-access-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.TROUBLE_TICKET_NO_ACCESS)"
            checked-value="TROUBLE_TICKET_NO_ACCESS" />
        </div>

        <div class="col-lg-3 col-sm-6">
          <label class="label">{{ $t('user-administration.net-business') }}</label>

          <tws-checkbox
            id="netbusiness-read-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.NETBUSINESS_READ)"
            checked-value="NETBUSINESS_READ" />

          <tws-checkbox
            id="netbusiness-qoute-creation-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.NETBUSINESS_QOUTE_CREATION)"
            checked-value="NETBUSINESS_QOUTE_CREATION" />

          <tws-checkbox
            id="netbusiness-order-creation-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.NETBUSINESS_ORDER_CREATION)"
            checked-value="NETBUSINESS_ORDER_CREATION" />

          <tws-checkbox
            id="netbusiness-manage-level-no-access-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.NETBUSINESS_MANAGE_LEVEL_NO_ACCESS)"
            checked-value="NETBUSINESS_MANAGE_LEVEL_NO_ACCESS" />
        </div>

        <div class="col-lg-3 col-sm-6">
          <label class="label">{{ $t('user-administration.phone-subscription') }}</label>

          <tws-checkbox
            id="phone-subscription-order-creation-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.PHONE_SUBCRIPTION_ORDER_CREATION)"
            checked-value="PHONE_SUBCRIPTION_ORDER_CREATION" />

          <tws-checkbox
            id="phone-subscription-order-management-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.PHONE_SUBCRIPTION_ORDER_MANAGMENT)"
            checked-value="PHONE_SUBCRIPTION_ORDER_MANAGMENT" />

          <tws-checkbox
            id="phone-subscription-fault-handling-filter"
            v-model="additionalFiltersModel"
            :label="$t(userRoles.PHONE_SUBCRIPTION_FAULT_HANDLING)"
            checked-value="PHONE_SUBCRIPTION_FAULT_HANDLING" />
        </div>

        <template v-if="!isDelegatedAdmin">
          <div class="col-lg-3 col-sm-6">
            <label class="label">{{ $t('user-administration.web-tab') }}</label>

            <tws-checkbox
              id="webtab-admin-tab-admin-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_TAB_ADMIN)"
              checked-value="WEBTAB_ADMIN_TAB_ADMIN" />

            <tws-checkbox
              id="webtab-admin-operator-customer-service-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE)"
              checked-value="WEBTAB_ADMIN_OPERATOR_CUSTOMER_SERVICE" />

            <tws-checkbox
              id="webtab-admin-network-defect-administrator-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR)"
              checked-value="WEBTAB_ADMIN_NETWORK_DEFECT_ADMINISTRATOR" />

            <tws-checkbox
              id="webtab-admin-superuser-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_SUPERUSER)"
              checked-value="WEBTAB_ADMIN_SUPERUSER" />

            <tws-checkbox
              id="webtab-admin-maintenance-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_MAINTENANCE)"
              checked-value="WEBTAB_ADMIN_MAINTENANCE" />

            <tws-checkbox
              id="webtab-admin-tab-porting-internal-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_TAB_PORTING_INTERNAL)"
              checked-value="WEBTAB_ADMIN_TAB_PORTING_INTERNAL" />

            <tws-checkbox
              id="webtab-admin-tab-read-only-internal-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL)"
              checked-value="WEBTAB_ADMIN_TAB_READ_ONLY_INTERNAL" />
          </div>

          <div class="col-lg-3 col-sm-6">
            <label class="label">{{ $t('user-administration.administrator-roles') }}</label>

            <tws-checkbox
              id="administrator-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.ADMINISTRATOR)"
              checked-value="ADMINISTRATOR" />

            <tws-checkbox
              id="delegated-administrator-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.DELEGATED_ADMINISTRATOR)"
              checked-value="DELEGATED_ADMINISTRATOR" />

            <tws-checkbox
              v-if="isSuperUser"
              id="billing-admin-filter"
              v-model="additionalFiltersModel"
              :label="$t(userRoles.BILLING_ADMINISTRATOR)"
              checked-value="BILLING_ADMINISTRATOR" />
          </div>
        </template>

        <div class="col-lg-3 col-sm-6">
          <label class="label">{{ $t('user-administration.account-status') }}</label>

          <tws-checkbox
            id="inactive-status-filter"
            v-model="statusFiltersModel"
            :label="$t('user-administration.statuses.inactive')"
            :checked-value="accountStatus.INACTIVE" />

          <tws-checkbox
            id="expired-password-status-filter"
            v-model="statusFiltersModel"
            :label="$t('user-administration.statuses.password-expired')"
            :checked-value="accountStatus.PASSWORD_EXPIRED" />

          <tws-checkbox
            id="locked-status-filter"
            v-model="statusFiltersModel"
            :label="$t('user-administration.statuses.locked')"
            :checked-value="accountStatus.LOCKED" />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <a
            role="button"
            class="reset-button"
            @click="resetAdditionalFilters">
            <tws-reset-filter-icon />&nbsp;
            <span>{{ $t('common.reset-filter') }}</span>
          </a>
        </div>
      </div>
    </div>

    <div
      v-if="loading || exception"
      class="row">
      <div class="col">
        <InfraPageAlert
          v-if="loading"
          type="info">
          {{ $t('user-administration.loading') }}
        </InfraPageAlert>

        <InfraPageAlert
          v-if="exception"
          type="danger">
          {{ $t('user-administration.loading-failed') }}
        </InfraPageAlert>
      </div>
    </div>

    <div
      v-else
      class="row">
      <div class="col">
        <tws-paginated-table
          v-if="filteredUsers.length > 0"
          :items="filteredUsers"
          :current-page="currentPage"
          :extra-page-count="5"
          :item-count-on-page="20"
          aria-label="user-search"
          data-cy="user-administration-list"
          @onRowClick="onUserRowClick"
          @onPageChange="onPageChange">
          <template #thead>
            <tr>
              <th scope="col">
                {{ $t('user-administration.table-headings.username') }}
              </th>
              <th scope="col">
                {{ $t('user-administration.table-headings.name') }}
              </th>
              <th scope="col">
                {{ $t('user-administration.table-headings.status') }}
              </th>
              <th scope="col">
                {{ $t('user-administration.table-headings.valid-until') }}
              </th>
              <th
                v-if="isSuperUserOrAdmin"
                scope="col">
                {{ $t('user-administration.table-headings.company') }}
              </th>
            </tr>
          </template>

          <template #tr="{ item }">
            <td>{{ item.username }}</td>
            <td>{{ item.firstName + " " + item.lastName }}</td>
            <td>{{ $t(accountStatusText[item.accountStatus]) }}</td>
            <td>{{ item.accountExpirationDate }}</td>
            <td v-if="isSuperUserOrAdmin">
              {{ item.company ? item.company.name : '' }}
            </td>
          </template>
        </tws-paginated-table>
        <div
          v-else
          class="empty-result-block">
          <p>
            {{ $t('user-administration.no-data') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import XLSX from 'xlsx'

import { SortOptions } from '@/store/modules/user-administration.store.js'
import { USER_ROLES } from '@/constants/user-roles.js'

import {
  ACCOUNT_STATUS, ACCOUNT_STATUS_TEXT
} from '@/constants/account-status.js'

import {
  TwsBorderlessButton, TwsBorderlessCustomContentButton, TwsBorderlessDropdown, TwsCheckbox, InfraPageAlert, TwsPaginatedTable
} from 'tws-vue-components'

export default {
  components: {
    TwsBorderlessButton,
    TwsBorderlessCustomContentButton,
    TwsBorderlessDropdown,
    TwsCheckbox,
    InfraPageAlert,
    TwsPaginatedTable
  },
  data () {
    return {
      redirectAction: {
        username: this.$route.query.username,
        action: this.$route.query['redirect-action']
      },
      accountStatusText: ACCOUNT_STATUS_TEXT,
      accountStatus: ACCOUNT_STATUS,
      userRoles: USER_ROLES,
      showFilters: false,
      sortingItems: [
        {
          key: SortOptions.USERNAME_ASC,
          value: this.$t('user-administration.sort-labels.username-ascending')
        },
        {
          key: SortOptions.USERNAME_DESC,
          value: this.$t('user-administration.sort-labels.username-descending')
        },
        {
          key: SortOptions.LAST_NAME_ASC,
          value: this.$t('user-administration.sort-labels.last-name-ascending')
        },
        {
          key: SortOptions.LAST_NAME_DESC,
          value: this.$t('user-administration.sort-labels.last-name-descending')
        },
        {
          key: SortOptions.VALID_UNTIL_DESC,
          value: this.$t('user-administration.sort-labels.valid-until-descending')
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'userAdministration/isLoading',
      loaded: 'userAdministration/isLoaded',
      exception: 'userAdministration/isException',
      filteredUsers: 'userAdministration/getFilteredUsers',
      sortBy: 'userAdministration/getSortBy',
      ascendingSort: 'userAdministration/isAscendingSort',
      currentPage: 'userAdministration/getCurrentPage',
      searchFilter: 'userAdministration/getSearchFilter',
      additionalFilters: 'userAdministration/getAdditionalFilters',
      statusFilters: 'userAdministration/getStatusFilters'
    }),
    ...mapGetters('eamCookies', [
      'isDelegatedAdmin',
      'isSuperUser',
      'isAdmin'
    ]),
    additionalFiltersModel: {
      get () {
        return this.additionalFilters
      },
      set (value) {
        this.$store.dispatch('userAdministration/changeAdditionalFilters', value)
      }
    },
    searchFilterModel: {
      get () {
        return this.searchFilter
      },
      set (value) {
        this.$store.dispatch('userAdministration/changeSearchFilter', value)
      }
    },
    statusFiltersModel: {
      get () {
        return this.statusFilters
      },
      set (value) {
        this.$store.dispatch('userAdministration/changeStatusFilters', value)
      }
    },
    redirectMessage () {
      if (this.redirectAction.action === 'created') {
        return this.$t('user-administration.messages.created', [this.redirectAction.username])
      } else if (this.redirectAction.action === 'deleted') {
        return this.$t('user-administration.messages.deleted', [this.redirectAction.username])
      } else if (this.isUserPartiallyCreated) {
        return this.$t('user-administration.messages.partially-created', [this.redirectAction.username])
      }
      return null
    },
    isUserPartiallyCreated () {
      return this.redirectAction.action === 'partially-created'
    },
    isSuperUserOrAdmin () {
      return this.isSuperUser || this.isAdmin
    },
    isSuperUserOrDelegatedAdmin () {
      return this.isSuperUser || this.isDelegatedAdmin
    }
  },
  mounted () {
    this.$store.dispatch('userAdministration/fetchUsers')
  },
  methods: {
    resetAdditionalFilters () {
      this.$store.dispatch('userAdministration/resetAdditionalFilters')
    },
    onUserRowClick (user) {
      this.$router.push(`${this.pathLocale}${process.env.VUE_APP_USER_DETAILS_PATH}?userId=${user.userId}`)
    },
    toggleAdditionalFilters () {
      this.showFilters = !this.showFilters
    },
    onPageChange (page) {
      this.$store.dispatch('userAdministration/changeCurrentPage', page)
    },
    onSortChange (value) {
      this.$store.dispatch('userAdministration/changeSortBy', value)
    },
    transformRoles (roles) {
      return Object.keys(roles).filter(key => roles[key] === true).map(key => this.userRoles[key] ? this.$t(this.userRoles[key]) : key).toString()
    },
    generateExportData () {
      const data =
        [[
          this.$t('user-administration.table-headings.id'),
          this.$t('user-administration.table-headings.username'),
          this.$t('user-administration.table-headings.name'),
          this.$t('user-administration.table-headings.last-name'),
          this.$t('user-administration.table-headings.company'),
          this.$t('user-administration.table-headings.status'),
          this.$t('user-administration.table-headings.valid-until'),
          this.$t('user-administration.table-headings.roles'),
          this.$t('user-administration.table-headings.last-log-in'),
          this.$t('user-administration.table-headings.last-failed-log-in'),
          this.$t('user-administration.table-headings.created-at'),
          this.$t('user-administration.table-headings.email'),
          this.$t('user-administration.table-headings.last-change'),
          this.$t('user-administration.table-headings.phone'),
          this.$t('user-administration.table-headings.mobile-phone')
        ]]
      this.filteredUsers.forEach(user => {
        data.push([
          user.userId,
          user.username,
          user.firstName,
          user.lastName,
          user.company ? user.company.name : '',
          user.accountStatus,
          user.accountExpirationDate ? user.accountExpirationDate : '',
          user.roles ? this.transformRoles(user.roles) : '',
          user.lastLoginDate || '',
          user.lastFailedLoginDate || '',
          user.createdDate || '',
          user.email,
          user.modifiedDate || '',
          user.phone || '',
          user.mobilePhone || ''
        ])
      })
      return data
    },
    exportResults (extension) {
      const fileName = this.$t('user-administration.export-file-name')
      const worksheet = XLSX.utils.aoa_to_sheet(this.generateExportData())
      const workbook = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(workbook, worksheet, fileName)
      XLSX.writeFile(workbook, `${fileName}.${extension}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "tws-core-atoms/variables.scss";

.container {
  padding-top: 36px;
  margin-bottom: 36px;
}

.icon {
  margin-left: 0.7rem;
}

.search-icon {
  position: absolute;
  margin-left: 1rem;
  margin-top: 1rem;
}

.user-search-field {
  margin-bottom: 0;

  input {
    background-color: $light-grey;
    border-radius: 50px;
    border-color: $grey;
    padding-left: 46px;
  }

  i {
    position: absolute;
    top: 17px;
    left: 18px;
  }
}

.full-name-column {
  font-weight: bold;
}

.empty-result-block {
  border: 1px solid $medium-grey;
  padding: 20px 30px;
  margin: 0 200px;
  text-align: center;

  p {
    margin-top: 0;
    margin-bottom: 0;
    max-width: 790px;
  }
}

.redirect-action-row {
  margin-bottom: 36px;
}

.actions-row {
  margin-bottom: 40px;
}

.result-actions {
  float: right;
  display: flex;

  span, select {
    font-size: 16px;
  }
}

.result-action {
  margin: 10px;
  padding: 0;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

.export-buttons {
  display: flex;
}

.export-button {
  background-color: #fff;
  padding: 24px;
  border: none;
  text-align: center;
  font-size: 16px;
  line-height: 1;
  outline: none;

  &:hover {
    cursor: pointer;
  }
}

.active-filter-list {
  position: relative;
  display: block;
  border-bottom: 3px solid;
  padding-bottom: 3px;
  margin-bottom: -6px;
}

.reset-button {
  cursor: pointer;
}

.filters-wrapper {
  margin-bottom: 25px;
}

.grouped-form-controls {
  .form-control:not(:last-of-type) {
    margin-bottom: 0;
  }

  label {
    font-weight: bold;
  }
}
</style>
