<template>
  <div>
    <GrootErrorPage
      v-if="exception"
      :language="locale" />
    <div
      v-else
      class="ticket-list">
      <GrootHeroBanner
        :title="$t('ticket-list.heading')"
        :preamble="$t('ticket-list.preamble')"
        :breadcrumbs="breadcrumbItems">
        <div class="ticket-list--cta">
          <PurpurCtaLink
            class="ticket-list--cta--link"
            href=""
            size="md"
            variant="primary-negative"
            @click.native="goToCreateNewTicket">
            {{ $t('ticket-list.create-new-case') }}
          </PurpurCtaLink>
        </div>
      </GrootHeroBanner>
      <TicketListContainer
        :userName="userName"
        :ticketsData="mergedTickets"
        :loading="loading"
        :onViewTicket="viewTicket"
        :language="locale"
        :isPartialData="partialDataWarning"
        :exception="exception" />
    </div>
  </div>
</template>

<script>
import PurpurCtaLink from '@/apps/public-landing/components/purpur/PurpurCtaLink'
import { BREADCRUMBS_CUSTOMER_TICKETS } from '@/constants/breadcrumbs-constants'
import { applyReactInVueWithOptions } from '@/utils/react.utils'
import UserUtils from '@/utils/user.utils'
import {
  GrootHeroBanner, GrootTicketListContainer, GrootErrorPage
} from 'groot-components'
import {
  mapActions,
  mapState
} from 'vuex'

export default {
  components: {
    PurpurCtaLink,
    GrootHeroBanner: applyReactInVueWithOptions(GrootHeroBanner),
    TicketListContainer: applyReactInVueWithOptions(GrootTicketListContainer),
    GrootErrorPage: applyReactInVueWithOptions(GrootErrorPage)
  },
  data () {
    return {
      userName: UserUtils.username(),
      locale: this.$route.params?.locale,
      breadcrumbItems: BREADCRUMBS_CUSTOMER_TICKETS.LIST,
      showHelpDialog: false
    }
  },
  computed: {
    ...mapState('CustomerTickets/TicketListNew', [
      'loading',
      'mergedTickets',
      'partialDataWarning',
      'exception'
    ]),
    ...mapState('CustomerTickets', ['content'])
  },
  mounted () {
    this.loadTicketsInParallel()
  },
  methods: {
    ...mapActions('CustomerTickets/TicketListNew', ['loadTicketsInParallel']),
    goToCreateNewTicket () {
      this.$router.push({
        name: 'tickets-create',
        params: this.$route.params
      })
    },
    toggleHelpDialog () {
      this.showHelpDialog = !this.showHelpDialog
    },
    closeHelpDialog () {
      this.showHelpDialog = false
    },
    viewTicket (supportId) {
      this.$router.push({
        name: 'tickets-details',
        params: {
          supportId,
          locale: this.$route.params.locale
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "groot-components/dist/style.css";
.ticket-list {
  &--cta {
    display: flex;
    gap: 15px;

    @media (max-width: 640px) {
      flex-direction: column;
    }

    &--link {
      ::v-deep [class*="_purpur-cta-link"],
      ::v-deep [class*="_purpur-button"] {
        width: 100%;
      }
    }
  }
  &-modal::v-deep .modal-container {
    min-height: 0;
    padding: 2rem;
  }
}
</style>
