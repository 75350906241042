<template>
  <div
    class="container dynamic-form">
    <slot />

    <tws-button-primary
      v-if="labels.submit_button_text"
      class="button"
      :disabled="isButtonDisabled"
      @click="postDynamicForm">
      {{ labels.submit_button_text }}
    </tws-button-primary>

    <InfraPageAlert v-show="formSent">
      {{ labels.success_message }}
      <span v-if="!isHTMLForm">
        <LocalisedLink
          class="link"
          :to="'/tickets/details/' + id"
          :exact="true">
          {{ id + '.' }}
        </LocalisedLink>
      </span>
    </InfraPageAlert>

    <InfraPageAlert
      v-show="!valid"
      type="info">
      {{ labels.validation_message }}
    </InfraPageAlert>
    <InfraPageAlert
      v-show="isError"
      type="danger">
      {{ $t('common.state.error') }}
    </InfraPageAlert>
  </div>
</template>

<script>
import {
  mapActions,
  mapGetters
} from 'vuex'

import {
  TwsButtonPrimary, InfraPageAlert
} from 'tws-vue-components'

import DynamicFormService from '../services/dynamic-form.service'

import { isLoggedIn } from '@/utils/user.utils'
import TicketService from '@/apps/customer-tickets/service/ticket.service'

export default {
  name: 'TwsForm',
  components: {
    TwsButtonPrimary,
    InfraPageAlert
  },
  props: {
    labels: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      validForm: {
        type: Boolean,
        required: true
      },
      validationMessages: {
        message: this.labels.validation_message
      },
      valid: true,
      mounted: false,
      formSent: false,
      isButtonDisabled: false,
      isError: false,
      id: null
    }
  },
  computed: {
    ...mapGetters('DynamicForm',
      [
        'getValidator',
        'getEmailAddresses',
        'getFormFieldsHtml',
        'getFormFields',
        'getFieldValue',
        'getFieldValueMap'
      ]
    ),
    isHTMLForm () {
      return this.labels.form_name === 'kundportal' ||
          this.labels.form_name === 'becomeCustomer' ||
          this.labels.form_name === 'agreement-support' ||
          this.labels.form_name === 'utbyggnadslagen' ||
          this.labels.form_name === 'public'
    }
  },
  mounted () {
    if (isLoggedIn()) {
      this.fetchUser()
    }

    this.addFormInformation(
      {
        emailAddresses: this.labels.email_addresses,
        signature: this.labels.email_signature,
        fieldDependencyMessage: this.labels.dependent_fields_message
      }
    )

    this.formMounted()
    this.mounted = true
  },
  destroyed () {
    this.resetState()
    this.mounted = false
  },
  methods: {
    ...mapActions('DynamicForm/UserDetails', ['fetchUser']),
    generateTemplateText () {
      let updated = this.labels.template_text

      if (updated) {
        Object.keys(this.getFieldValueMap).forEach(key => {
          updated = updated.replace('${' + key + '}', this.getFieldValueMap[key])
        })

        return updated
      }

      return ''
    },
    computeProductString (str) {
      const isValueArray = Array.isArray(str)
      const string = isValueArray ? str[0] : str

      return string ? string.replace(/DUPPPLICATE/g, '') : undefined
    },
    postDynamicForm () {
      if (this.isHTMLForm) {
        this.postFormHTML()
      } else {
        this.postForm()
      }
    },
    async postFormHTML () {

      this.formSent = false
      this.getValidator.formObject.$touch()
      this.valid = !this.getValidator.formObject.$invalid && this.getEmailAddresses.length > 0

      if (!this.valid) {
        return
      }

      const data = {
        from: DynamicFormService.parseExpression(this.getFieldValueMap, this.labels.from_email),
        emails: this.getEmailAddresses,
        subject: DynamicFormService.parseExpression(this.getFieldValueMap, this.labels.email_subject),
        html: this.generateTemplateText() + this.getFormFieldsHtml
      }

      try {
        this.isButtonDisabled = true
        this.isError = false

        this.formSent = false

        await DynamicFormService.postForm(JSON.stringify(data))

        this.resetForm()
        this.formSent = true

      } catch (err) {
        this.isError = true
        // eslint-disable-next-line no-console
        console.log(err)
      }
      finally {
        this.isButtonDisabled = false
      }

    },
    async postForm () {
      this.isButtonDisabled = true
      this.isError = false

      this.formSent = false
      this.id = null
      this.getValidator.formObject.$touch()
      this.valid = !this.getValidator.formObject.$invalid

      if (!this.valid) {
        this.isButtonDisabled = false
        return
      }

      const data = {
        subject: DynamicFormService.parseExpression(this.getFieldValueMap, this.labels.email_subject),
        responseEmail: this.getFieldValue('UserEmail') || this.getFieldValue('email'),
        content: this.getFormFields,
        caseType: this.labels.caseType,
        product: this.labels.product || this.computeProductString(this.getFieldValue('product')),
        ...this.getFieldValue('assetId') && { assetId: this.getFieldValue('assetId') },
        ...this.getFieldValue('ordernumber') && { orderId: this.getFieldValue('ordernumber') },
        ...this.getFieldValue('custref') && { customerReference: this.getFieldValue('custref') }
      }

      try {

        const response = await TicketService.createTicket(data)
        this.id = response.supportId

        if (!this.id) {
          this.isError = true
        } else {
          await TicketService.activateTicket(response.supportId)

          this.resetForm()
          this.formSent = true
        }

      } catch (err) {
        this.isError = true
        // eslint-disable-next-line no-console
        console.log(err)
      }
      finally {
        this.isButtonDisabled = false
      }
    },

    ...mapActions('DynamicForm',
      [
        'addFormInformation',
        'formMounted',
        'resetForm',
        'resetState'
      ]
    )
  }
}
</script>

<style  lang="scss" scoped>
.dynamic-form {
  margin-top: 48px;

  .button {
    margin-bottom: 25px;
  }
}
</style>
