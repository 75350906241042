import { InfraLocaleUtils } from 'tws-vue-components'

export const getSelectedLocale = () => {
  return InfraLocaleUtils.getSelectedLocale()
}

export const isLocaleEnglish = () => {
  return getSelectedLocale() === InfraLocaleUtils.LOCALES.EN
}

export const getIframeLocalisedURL = (url) => {
  return isLocaleEnglish() ? url + '&target=SUSA_ENU' : url + '&target=SUSA'
}
