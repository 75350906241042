import TicketService from '@/apps/customer-tickets/service/ticket.service'
// import { fetchBillingCaseList } from '@/apps/fault-reporting/services/teliaNOW.service'
// import { mapBillingCaseToTicket } from '@/apps/customer-tickets/utils/request.utils'

export default {
  namespaced: true,
  state: {
    tickets: [],
    teliaNOWcases: [],
    mergedTickets: [],
    loading: true,
    exception: false,
    partialDataWarning: false
  },
  mutations: {
    setTickets: (state, tickets) => state.tickets = tickets,
    setTeliaNOWCases: (state, cases) => state.teliaNOWcases = cases,
    setMergedTickets: (state, mergedTickets) => state.mergedTickets = mergedTickets,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception,
    setPartialDataWarning: (state, warning) => state.partialDataWarning = warning // New mutation
  },
  actions: {
    async loadTicketsInParallel ({ commit }) {
      commit('setLoading', true)
      commit('setException', false)
      commit('setPartialDataWarning', false)

      // TODO: uncomment for billing tickets release, check commit: 6aad5d906ee4ae5955c4c3b4175f26db9ca305c4
      let tickets = []
      //let cases = []
      let ticketsFailed = false
      //let casesFailed = false

      try {
        tickets = await TicketService.getTicketList()
      } catch (error) {
        ticketsFailed = true
      }

      if (ticketsFailed) {
        commit('setException', true)
      }
      // } else if (ticketsFailed || casesFailed) {
      //   commit('setPartialDataWarning', true)
      // }

      //const mappedCases = cases.map(mapBillingCaseToTicket)

      // const mergedTickets = [
      //   ...tickets,
      //   ...mappedCases
      // ]

      commit('setTickets', tickets)
      //commit('setTeliaNOWCases', cases)
      commit('setMergedTickets', tickets)
      commit('setLoading', false)
    }
  }
}
