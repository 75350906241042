import { render, staticRenderFns } from "./TicketListNewView.vue?vue&type=template&id=37225e14&scoped=true"
import script from "./TicketListNewView.vue?vue&type=script&lang=js"
export * from "./TicketListNewView.vue?vue&type=script&lang=js"
import style0 from "./TicketListNewView.vue?vue&type=style&index=0&id=37225e14&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37225e14",
  null
  
)

export default component.exports