import { readFile } from '@/utils/file.utils'
import { createInstance } from '@/services/axios.api'

const buildFileUploadConfig = (contentType) => {
  return {
    baseURL: process.env.VUE_APP_SUPPORT_TICKETS_SERVICE,
    headers: {
      'Content-Type': contentType
    }
  }
}

export const apiPiam = createInstance(process.env.VUE_APP_SUPPORT_TICKETS_SERVICE)

const getTicketList = async () => {
  const data = await apiPiam.get('/tickets')
  return data.contents
}

const findTicketsBySupportId = async (supportId) => {
  const data = await apiPiam.get(`/tickets?supportId=${supportId}`)
  return data.contents
}

const findTicketsByCustomerRef = async (customerRef) => {
  const data = await apiPiam.get(`/tickets?customerReference=${customerRef}`)
  return data.contents
}

export const findTicketsByQuery = async (query) => {
  const queryProxy = query.trim().replace(/[^\w-]/g, '')
  if (query && query.length > 2) {
    if (query.startsWith('2-')) {
      return findTicketsBySupportId(queryProxy)
    }
    return findTicketsByCustomerRef(queryProxy)
  }

  return []
}

const filterTickets = async (tickets, query) => {
  const queryProxy = query.trim().toLowerCase()
  const subjectPredicate = t => t.subject.toLowerCase().includes(queryProxy)
      || t.customerReference.toLowerCase().includes(queryProxy)
  return tickets.filter(subjectPredicate)
}

const getTicket = async (supportId) => {
  return await apiPiam.get(`/tickets/${supportId}`, { params: {
    timestamp: new Date().getTime()
  } })
}

const createTicket = async (request) => {
  return await apiPiam.post('/tickets', request)
}

const checkAuthorization = async () => {
  try {
    await apiPiam.get('/check')
    return true
  } catch {
    return false
  }
}

const activateTicket = async (supportId) => {
  const { data } = await apiPiam.patch(`/tickets/${supportId}`, {})
  return data
}

const putAttachment = async (supportId, attachment) => {
  try {
    const content = await readFile(attachment)
    const response = await apiPiam.put(
      `/tickets/${supportId}/attachments/${attachment.name}`,
      content,
      buildFileUploadConfig(attachment.type)
    )
    return response
  } catch {
    return null
  }
}

const removeAttachment = async (supportId, fileKey) => {
  const { data } = await apiPiam.delete(
    `/tickets/${supportId}/attachments/${fileKey}`
  )
  return data
}

const postNote = async (supportId, request) => {
  const { result } = await apiPiam.post(`/tickets/${supportId}/notes`, request)
  return result
}

export default {
  getTicketList,
  getTicket,
  createTicket,
  putAttachment,
  removeAttachment,
  checkAuthorization,
  postNote,
  activateTicket,
  findTicketsByQuery,
  filterTickets
}
