import ContentfulService from '@/apps/customer-tickets/service/contentful.service'
import TicketCreate from './ticket-create.store'
import TicketDetailsBilling from './ticket-details-billing.store'
import TicketDetails from './ticket-details.store'
import TicketListNew from './ticket-list-new.store'
import TicketList from './ticket-list.store'
import TicketNotesBilling from './ticket-notes-billing.store'
import TicketNotes from './ticket-notes.store'

export const CustomerTicketsModule = {
  namespaced: true,
  modules: {
    TicketDetails,
    TicketList,
    TicketNotes,
    TicketCreate,
    TicketListNew,
    TicketDetailsBilling,
    TicketNotesBilling
  },
  state: {
    content: null,
    exception: null,
    loading: false
  },
  actions: {
    async load ({ commit }) {
      try {
        commit('setContent', await ContentfulService.getContent())
      } catch {
        commit('setException', true)
      } finally {
        commit('setLoading', false)
      }
    }
  },
  mutations: {
    setContent: (state, content) => state.content = content,
    setLoading: (state, loading) => state.loading = loading,
    setException: (state, exception) => state.exception = exception
  }
}
