<template>
  <div>
    <LoginWrapper
      :redirectAction="redirectAction"
      :redirectMessage="redirectMessage"
      :language="locale" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { GrootTwsLoginView } from 'groot-components'
import { applyReactInVueWithOptions } from '@/utils/react.utils'

import { SESSION_KEY_RETURN_URI } from '@/common/auth-constants'

export default {
  components: {
    LoginWrapper: applyReactInVueWithOptions(GrootTwsLoginView)
  },
  data () {
    return {
      locale: this.$route.params?.locale,
      loginMethod: 'password',
      loginMethods: [
        {
          text: this.$t('login.methods.password'),
          value: 'password'
        },
        {
          text: this.$t('login.methods.one-time-code'),
          value: 'one-time-code'
        }
      ],
      returnUri: this.$route.query.ct_orig_uri,
      returnUriSessionStorage: sessionStorage.getItem(SESSION_KEY_RETURN_URI),
      errorCode: this.$route.query.dMessage,
      redirectAction: this.$route.query.action
    }
  },
  computed: {
    ...mapState('login', ['oneTimeCodeSent']),
    redirectMessage () {
      if (this.redirectAction === 'logout') {
        return this.$t('login.messages.logout')
      } else if (this.redirectAction === 'requestResetPassword') {
        return this.$t('login.messages.reset-password')
      } else if (this.redirectAction === 'login-required') {
        return this.$t('login.messages.login-private')
      }
      return null
    },
    errorMessage () {
      if (!this.errorCode || this.loginMethod === 'one-time-code') {
        return null
      }

      switch (this.errorCode) {
        case '3':
          return this.$t('login.errors.invalid-credentials')
        case '31':
          return this.$t('login.errors.account-locked')
        case '32':
          return this.$t('login.errors.password-expired')
        case '33':
          return this.$t('login.errors.unable-to-login')
        case 'GA3141':
          return this.$t('login.errors.password-changed')
        case 'sysUserLoginError':
          return this.$t('login.errors.system-account')
        default:
          return this.$t('login.errors.default')
      }
    }
  },
  created () {
    if (!this.returnUriSessionStorage && !this.returnUri && !this.errorCode) {
      sessionStorage.setItem(
        SESSION_KEY_RETURN_URI,
        process.env.VUE_APP_DASHBOARD_PATH
      )
    } else if (this.returnUri) {
      sessionStorage.setItem(SESSION_KEY_RETURN_URI, this.returnUri)
    }
  }
}
</script>
