import Vue from 'vue'

import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

import {
  email, emailRestricted, phone
} from '../validation-rules'

import UserDetailsModule from './user-details.store'

const dependentFieldIsInvalid = (state, dependentField) => {
  return state.validator.$v.formObject[dependentField] && state.validator.$v.formObject[dependentField].$invalid
}

const getDefaultState = () => {
  return {
    username: '',
    emailAddresses: {},
    signature: {},
    fieldDependencyMessage: {
      type: '',
      required: false
    },
    formFields: {},
    validationSchema: {},
    valid: true,
    validator: null
  }
}

export const DynamicFormModule = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getFormFieldsHtml (state) {
      let formHtml = ''
      Object.keys(state.formFields)
        .filter(key => state.formFields[key].value !== '')
        .forEach(key => {
          formHtml += '<p>' + state.signature[key] + ' : ' + state.formFields[key].value + '</p>'
        })
      return formHtml.replace(/\n/g, '<br/>')
    },
    getEmailAddresses (state) {
      const emails = []
      Object.values(state.emailAddresses).forEach(email => {
        email.split(';').forEach(e => emails.push(e))
      })
      return emails.filter((v, i, a) => a.indexOf(v) === i)
    },
    getFormFields (state) {
      let formHtml = ''
      Object.keys(state.formFields)
        .filter(key => state.formFields[key].value !== '')
        .forEach(key => {
          const fieldValue = Array.isArray(state.formFields[key].value)
            ? state.formFields[key].value
            : state.formFields[key].value.replace(/DUPPPLICATE/g, '')

          formHtml += state.signature[key] + ' : ' + fieldValue + '\n'
        })
      return formHtml
    },
    disabledField: (state) => (fieldName) => {
      if (state.validationSchema[fieldName]) {
        let isDisabled = false
        for (const dependentField of state.validationSchema[fieldName]) {
          isDisabled = isDisabled || dependentFieldIsInvalid(state, dependentField) || !state.formFields[dependentField] ||
          !state.formFields[dependentField].value
        }
        if (isDisabled) {
          state.formFields[fieldName].value = ''
        }
        return isDisabled
      }
      return false
    },
    getFieldDependencyMessage: (state) => (fieldName) => {
      let message = state.fieldDependencyMessage + ': |'
      if (state.validationSchema[fieldName]) {
        for (const dependentField of state.validationSchema[fieldName]) {
          if (dependentFieldIsInvalid(state, dependentField)) {
            message += state.formFields[dependentField].label + '|'
          }
        }
      }
      return message
    },
    getValidator: (state) => {
      return state.validator ? state.validator.$v : null
    },
    getValidatorFormField: (state) => (fieldName) => {
      return state.validator.$v.formObject[fieldName]
    },
    getFieldValue: (state) => (fieldName) => {
      return state.formFields[fieldName] && state.formFields[fieldName].value
    },
    getFieldValueMap: (state) => {
      const fieldValueMap = {}

      Object.keys(state.formFields)
        .forEach(key => {
          fieldValueMap[key] = state.formFields[key].value
        })
      return fieldValueMap
    },
    isValidatorCreated: (state) => {
      return state.validator
    }
  },
  actions: {
    addFormInformation ({ commit }, { emailAddresses, fieldDependencyMessage, username }) {
      commit('setFormInformation', {
        emailAddresses,
        fieldDependencyMessage,
        username
      })
    },
    fieldCreated ({ commit }, { fieldName, label, templateText, isFieldRequired, prefilledField }) {
      commit('setFormFieldName', {
        fieldName,
        label,
        templateText,
        isFieldRequired,
        prefilledField
      })
    },
    fieldValueEntered ({ commit }, { key, value, email, invalid }) {
      commit('setFormFieldValue', {
        key,
        value,
        email,
        invalid
      })
    },
    addValidation ({ commit }, { fieldName, validations }) {
      commit('setValidations', {
        fieldName,
        validations
      })
    },
    removeEmailByFieldName ({ commit }, fieldName) {
      commit('removeEmail', fieldName)
    },
    formMounted ({ commit }) {
      commit('setValidator')
    },
    resetForm ({ commit }) {
      commit('resetForm')
    },
    resetState ({ commit }) {
      commit('resetState')
    }
  },
  mutations: {
    setFormInformation (state, { emailAddresses, fieldDependencyMessage, username }) {
      state.username = username

      if (emailAddresses) {
        Object.values(emailAddresses)
          .map(e => e.recipient_email)
          .filter(email => email)
          .forEach(r => {
            Vue.set(state.emailAddresses, 'form-' + r, r)
          })
      }
      state.fieldDependencyMessage = fieldDependencyMessage
    },
    setFormFieldName (state, { fieldName, label, templateText, isFieldRequired, prefilledField }) {
      Vue.set(state.formFields, fieldName, {
        value: '',
        label: label,
        isFieldRequired: isFieldRequired,
        prefilledField: prefilledField
      })

      if (state.validator && !state.validator.$v.formObject[fieldName]) {
        state.validator.registerField(fieldName)
      }
      state.signature[fieldName] = templateText
    },
    setFormFieldValue (state, { key, value, email }) {
      Vue.set(state.formFields[key], 'value', value)
      state.validator.$data.formObject[key] = value
      if (email) {
        Vue.set(state.emailAddresses, key, email)
      }
    },
    removeEmail (state, fieldName) {
      state.emailAddresses = Object.keys(state.emailAddresses).reduce((object, key) => {
        if (key !== fieldName) {
          object[key] = state.emailAddresses[key]
        }
        return object
      }, {})
    },
    resetForm (state) {
      Object.keys(state.formFields).forEach(field => {
        Vue.set(state.formFields[field], 'value', '')
        Vue.set(state.validator.$v.formObject.$model, field, '')
      })
      state.validator.$v.formObject.$reset()
      state.emailAddresses = []
    },
    setValidations (state, { fieldName, validations }) {
      if (validations) {
        state.validationSchema[fieldName] = Object.values(validations).map(field => field.dependent_field)
      }
    },
    setValidator (state) {
      state.validator = new Vue({
        mixins: [validationMixin],
        data () {
          return {
            formObject: {}
          }
        },
        validations () {
          const rules = {
            formObject: {}
          }

          for (const key in state.formFields) {
            this.registerField(key, state.formFields[key].value || '')

            if (state.validationSchema[key]) {
              for (const dependentField of state.validationSchema[key]) {
                state.formFields[key].isFieldRequired = !!(state.formFields[dependentField] && state.formFields[dependentField].value)
              }
            }

            rules.formObject[key] = {}
            if (state.formFields[key].isFieldRequired) {
              rules.formObject[key] = {
                ...rules.formObject[key],
                required
              }
            }

            if (state.formFields[key].prefilledField === 'EMAIL') {
              rules.formObject[key] = {
                ...rules.formObject[key],
                email
              }
            }

            if (state.formFields[key].prefilledField === 'EMAIL_RESTRICTED') {
              rules.formObject[key] = {
                ...rules.formObject[key],
                emailRestricted
              }
            }

            if (state.formFields[key].prefilledField === 'PHONE_NUMBER') {
              rules.formObject[key] = {
                ...rules.formObject[key],
                phone
              }
            }
          }
          return rules
        },
        methods: {
          registerField (name, value) {
            this.$set(this.formObject, name, value)
          }
        }
      })
    },
    resetState: (state) => {
      Object.assign(state, getDefaultState())
    }
  },
  modules: {
    UserDetails: UserDetailsModule
  }
}
